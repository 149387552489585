<ng-container *ngIf="isActiveView('form')">

    <form [formGroup]="form" (ngSubmit)="formService.submit()">

        <div class="page_title">
            <div class="row marges">

                <div class="col y_items_center">
                    <h1 class="title">{{ 'quotation.value' | translate }}</h1>
                </div>

                <!-- Actions -->

                <div class="col_content y_items_center">

                    <ng-container *ngIf="origin">

                        <!-- Retour à l'origine -->

                        <button class="btn_cta btn_lines btn_little" type="button" (click)="openLeaveWithoutSubmitDialog()">
                            {{ origin.backLabel | translate }}
                        </button>

                    </ng-container>

                    <ng-container *ngIf="offerFormTabValidationService.currentItemIsLastItem">

                        <!-- Enregistrer -->

                        <button class="btn_cta btn_little" type="submit">
                            {{ 'quotation.mine.send.action.value'|translate }}
                        </button>

                    </ng-container>

                    <ng-container *ngIf="!offerFormTabValidationService.currentItemIsLastItem">

                        <!-- Passer à l'étape suivante -->

                        <button class="btn_cta btn_little" type="button" [disabled]="!offerFormTabValidationService.isCurrentItemValid" (click)="offerFormTabValidationService.goToNextItem()">
                            {{ 'form.tab.next.value' | translate }}
                        </button>

                    </ng-container>

                </div>
            </div>
        </div>

        <!-- Gestion des onglets -->

        <mat-tab-group #tabGroup>

            <ng-container *ngFor="let tabItem of tabItems">

                <mat-tab [disabled]="!offerFormTabValidationService.isFirstItem(tabItem.tag) && !offerFormTabValidationService.isPreviousItemValid(tabItem.tag)">
                    <ng-template mat-tab-label>
                        <span>{{ tabItem.label | translate }}</span>
                        <span [ngClass]="offerFormTabValidationService.getTabLabelClasses(tabItem.tag)"></span>
                    </ng-template>
                    <ng-container *ngTemplateOutlet="tabItem.template"></ng-container>
                </mat-tab>

            </ng-container>

        </mat-tab-group>

    </form>

</ng-container>

<ng-container *ngIf="isActiveView('confirmation')">

    <ng-container *ngTemplateOutlet="confirmationTemplate"></ng-container>

</ng-container>

<!-- Sélection des créateurs d'offres -->

<ng-template #offerCreators>

    <ng-container [formGroup]="form">

        <div class="tab_content_overflow content_offer_creators">

            <!-- Créateurs d'offres sélectionnés -->

            <ng-container *ngIf="offerCreatorSearchService.selectedOfferCreators$.value.length">

                <div class="row marges">
                    <div class="col y_items_center">

                        <div class="selected_offers" style="margin-top: 0; margin-bottom: 12px;">

                            <span>{{ 'offerCreator.list.selected.value' | translate }} :</span>
                            <ul>
                                <li *ngFor="let offerCreator of offerCreatorSearchService.selectedOfferCreators$.value">{{ offerCreator.name }}<mat-icon (click)="offerCreatorSearchService.unselectOfferCreator(offerCreator)">close</mat-icon></li>
                            </ul>
                        </div>
                    </div>

                    <div class="col_content y_items_center" style="margin-top: -12px;">
                        <button type="button" class="btn_cta btn_little" (click)="resetOfferCreatorSelection() ">{{ 'offerCreator.plural.unselection.action.value' | translate}}</button>
                    </div>

                </div>

            </ng-container>

            <!-- Liste des créateurs d'offres -->

            <app-core-offer-creator-search [mode]="'quotation'"></app-core-offer-creator-search>

        </div>

    </ng-container>

</ng-template>

<ng-template #formData>

    <ng-container [formGroup]="form">

        <div class="form_part">

            <div class="row marges ligne_form">

                <!-- Référence interne -->

                <div class="col_12 col_md_4">

                    <div class="form-group">
                        <input type="text" value="" placeholder=" " class="form-control" formControlName="ownerReference" id="ownerReference">
                        <label for="ownerReference">{{ 'offer.form.fields.reference.value' | translate }}</label>
                    </div>

                </div>

                <!-- Cible de clientèle -->

                <div class="col_12 col_md_4">

                    <div class="form-group" formGroupName="configuration">
                        <mat-select [compareWith]="compareById" formControlName="customerTypology">
                            <mat-option *ngFor="let customerTypology of availableCustomerTypologies" [value]="customerTypology">
                                {{ translationService.getFallbackTranslation(customerTypology.translations).name }}
                            </mat-option>
                        </mat-select>
                        <mat-label class="required">{{ 'customer.target.value' | translate }}</mat-label>
                        <span class="message-help"><mat-icon>info</mat-icon>{{ 'quotation.customer.target.help.value' | translate }}</span>
                        <mat-error *ngIf="configurationControl.get('customerTypology').errors && (configurationControl.get('customerTypology').dirty || configurationControl.get('customerTypology').touched)">
                            <div class="invalid-feedback">
                                <div *ngIf="configurationControl.get('customerTypology').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                            </div>
                        </mat-error>
                    </div>

                </div>

            </div>
            <div class="row marges ligne_form">

                <ng-container *ngIf="configurationControl.get('customerTypology').value?.type === 'I'">

                    <!-- Format bon cadeau -->

                    <div class="col_12">

                        <div class="form-group" formGroupName="configuration">
                            <label>{{'giftVoucher.format.question.value' | translate}}</label>
                            <div class="radio">
                                <input type="radio" [value]="true" formControlName="allowGiftVoucher" class="form-control" id="allowGiftVoucherYes">
                                <label for="allowGiftVoucherYes">{{'form.user.fields.yes.value' | translate}}</label>
                            </div>
                            <div class="radio">
                                <input type="radio" [value]="false" formControlName="allowGiftVoucher" class="form-control" id="allowGiftVoucherNo">
                                <label for="allowGiftVoucherNo">{{'form.user.fields.no.value' | translate}}</label>
                            </div>
                            <mat-error *ngIf="configurationControl.get('allowGiftVoucher').errors && (configurationControl.get('allowGiftVoucher').dirty || configurationControl.get('allowGiftVoucher').touched)">
                                <div class="invalid-feedback">
                                    <div *ngIf="configurationControl.get('allowGiftVoucher').errors.isRequired">{{ 'form.control.error.required.value' | translate }}</div>
                                </div>
                            </mat-error>
                        </div>
                    </div>

                </ng-container>

            </div>

        </div>

        <div class="form_part">

            <mat-accordion class="gradient">
                <mat-expansion-panel [expanded]="true">
                    <mat-expansion-panel-header class="col_md_8">
                        <mat-panel-title>{{ 'quotation.form.dates' | translate }}</mat-panel-title>
                    </mat-expansion-panel-header>
                    <div>

                        <ng-container *ngIf="!configurationControl.get('allowGiftVoucher').value">

                            <div class="row marges ligne_form">

                                <!-- Dates de séjour -->

                                <div class="col_12 col_md_4">
                                    <div class="form-group" formGroupName="composition">
                                        <input type="text" class="datetimepicker" id="dateStart" placeholder=" " matInput formControlName="dateStart" [matDatepicker]="dateStartPicker" (click)="dateStartPicker.open()" (dateChange)="dateEndPicker.open()">
                                        <label for="dateStart" class="required">{{ 'filter.dateInterval.start.value' | translate }}</label>
                                        <mat-datepicker #dateStartPicker></mat-datepicker>
                                    </div>
                                </div>

                                <div class="col_12 col_md_4">
                                    <div class="form-group" formGroupName="composition">
                                        <input type="text" class="datetimepicker" id="dateEnd" placeholder=" " matInput formControlName="dateEnd" [matDatepicker]="dateEndPicker" (click)="dateEndPicker.open()" [min]="minStayDateEnd">
                                        <label for="dateEnd" class="required">{{ 'filter.dateInterval.end.value' | translate }}</label>
                                        <mat-datepicker #dateEndPicker></mat-datepicker>
                                    </div>
                                </div>
                            </div>

                            <div class="row" *ngIf="compositionControl.get('dateEnd').errors && (compositionControl.get('dateEnd').dirty || compositionControl.get('dateEnd').touched)">
                                <div class="col_12 col_md_4"></div>
                                <div class="col_12 col_md_4">
                                    <div class="form-group">
                                        <mat-error>
                                            <div class="invalid-feedback">
                                                <div *ngIf="compositionControl.get('dateEnd').errors.isRequired">{{ 'form.control.error.required.value' | translate }}</div>
                                            </div>
                                        </mat-error>
                                    </div>
                                </div>
                            </div>

                        </ng-container>

                        <div class="row marges ligne_form">

                            <ng-container *ngIf="!configurationControl.get('allowGiftVoucher').value">

                                <!-- Flexibilité dates -->

                                <div class="col_12 col_md_4">
                                    <div class="form-group" formGroupName="composition">
                                        <mat-select [compareWith]="compareById" formControlName="flexibility">
                                            <mat-option *ngFor="let flexibility of flexibilities$ | async" [value]="flexibility">
                                                {{ translationService.getFallbackTranslation(flexibility.translations).label }}
                                            </mat-option>
                                        </mat-select>
                                        <mat-label class="required">{{ 'dates.plural.flexibility.value' | translate }}</mat-label>
                                        <mat-error *ngIf="compositionControl.get('flexibility').invalid && (compositionControl.get('flexibility').dirty || compositionControl.get('flexibility').touched)">
                                            <div class="invalid-feedback">
                                                <div *ngIf="compositionControl.get('flexibility').errors.isRequired">{{ 'form.control.error.required.value' | translate }}</div>
                                            </div>
                                        </mat-error>
                                    </div>
                                </div>

                            </ng-container>

                            <!-- Durée du séjour -->

                            <div class="col_12 col_md_4">
                                <div class="form-group" formGroupName="composition">
                                    <mat-select [compareWith]="compareById" formControlName="duration">
                                        <mat-option *ngFor="let duration of durations$ | async" [value]="duration">
                                            {{ translationService.getFallbackTranslation(duration.translations).label }}
                                        </mat-option>
                                    </mat-select>
                                    <mat-label class="required">{{ 'offer.booking.stay.duration.value' | translate }}</mat-label>
                                    <mat-error *ngIf="compositionControl.get('duration').invalid && (compositionControl.get('duration').dirty || compositionControl.get('duration').touched)">
                                        <div class="invalid-feedback">
                                            <div *ngIf="compositionControl.get('duration').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                        </div>
                                    </mat-error>
                                </div>
                            </div>

                        </div>

                        <div class="row marges ligne_form">

                            <!-- Destinations -->

                            <div class="col_12 col_md_4">

                                <div class="form-group" formGroupName="configuration">
                                    <mat-select multiple [compareWith]="compareById" formControlName="regions">
                                        <mat-option *ngFor="let region of availableRegions" [value]="region">
                                            {{ translationService.getFallbackTranslation(region.translations).label }}
                                        </mat-option>
                                    </mat-select>
                                    <mat-label class="required">{{ 'destination.plural.value' | translate }}</mat-label>
                                    <span class="message-help"><mat-icon>info</mat-icon>{{ 'quotation.region.help.value' | translate }}</span>
                                    <mat-error *ngIf="configurationControl.get('regions').errors && (configurationControl.get('regions').dirty || configurationControl.get('regions').touched)">
                                        <div class="invalid-feedback">
                                            <div *ngIf="configurationControl.get('regions').errors.minLengthArray">{{ 'form.control.error.minLengthArray.value' | translate: { min: 1 } }}</div>
                                        </div>
                                    </mat-error>
                                </div>
                            </div>

                            <!-- Commentaire concernant la destination -->

                            <div class="col_12 col_md_4">
                                <div class="form-group" formGroupName="configuration">
                                    <textarea placeholder=" " class="form-control" formControlName="regionComment" id="regionComment"></textarea>
                                    <label for="regionComment">{{ 'quotation.configuration.regionComment.description.value' | translate }}</label>
                                    <span class="message-help"><mat-icon class="tooltip_icon">info</mat-icon>{{ 'form.validation.error.maxLength' | translate:{ value: maxLengthComment } }}</span>
                                    <mat-error *ngIf="configurationControl.get('regionComment').errors && (configurationControl.get('regionComment').dirty || configurationControl.get('regionComment').touched)">
                                        <div class="invalid-feedback">
                                            <div *ngIf="configurationControl.get('regionComment').errors.maxlength">{{ 'form.control.error.maxLength.value' | translate: { maxLength : maxLengthComment } }}</div>
                                        </div>
                                    </mat-error>
                                </div>
                            </div>

                        </div>

                    </div>
                </mat-expansion-panel>
            </mat-accordion>

        </div>

        <div class="form_part">

            <mat-accordion class="gradient">
                <mat-expansion-panel [expanded]="false">
                    <mat-expansion-panel-header class="col_md_8">
                        <mat-panel-title>{{ 'quotation.form.composition' | translate }}</mat-panel-title>
                    </mat-expansion-panel-header>
                    <div>

                        <div class="row marges ligne_form">

                            <!-- Nombre d'adultes -->

                            <div class="col_12 col_md_4">

                                <div class="form-group" formGroupName="composition">
                                    <input type="text" value="" placeholder=" " class="form-control" formControlName="nbAdult" id="nbAdult">
                                    <label for="nbAdult" class="required">{{ 'adult.count.value' | translate }}</label>
                                    <mat-error *ngIf="compositionControl.get('nbAdult').errors && (compositionControl.get('nbAdult').dirty || compositionControl.get('nbAdult').touched)">
                                        <div class="invalid-feedback">
                                            <div *ngIf="compositionControl.get('nbAdult').errors.pattern">{{ 'form.control.error.pattern.value' | translate }}</div>
                                        </div>
                                    </mat-error>
                                </div>

                            </div>

                            <!-- Nombre d'enfants -->

                            <div class="col_12 col_md_4">

                                <div class="form-group" formGroupName="composition">
                                    <input type="text" value="" placeholder=" " class="form-control" formControlName="nbChild" id="nbChild">
                                    <label for="nbChild" class="required">{{ 'child.count.value' | translate }}</label>
                                    <mat-error *ngIf="compositionControl.get('nbChild').errors && (compositionControl.get('nbChild').dirty || compositionControl.get('nbChild').touched)">
                                        <div class="invalid-feedback">
                                            <div *ngIf="compositionControl.get('nbChild').errors.pattern">{{ 'form.control.error.pattern.value' | translate }}</div>
                                        </div>
                                    </mat-error>
                                </div>

                            </div>

                        </div>

                        <ng-container *ngIf="((compositionControl.get('nbAdult').dirty || compositionControl.get('nbAdult').touched) && compositionControl.get('nbAdult').errors?.isRequired) && ((compositionControl.get('nbChild').dirty || compositionControl.get('nbChild').touched) && compositionControl.get('nbChild').errors?.isRequired)">

                            <div class="row marges ligne_form">

                                <div class="col_12">
                                    <div class="form-group">
                                        <mat-error>
                                            <div class="invalid-feedback">
                                                <div>{{ 'quotation.participant.error.required.value' | translate }}</div>
                                            </div>
                                        </mat-error>
                                    </div>
                                </div>

                            </div>

                        </ng-container>

                        <ng-container *ngIf="hasChild">

                            <!-- Commentaire concernant les enfants -->

                            <div class="row marges ligne_form">

                                <div class="col_12 col_md_8">
                                    <div class="form-group" formGroupName="composition">
                                        <textarea placeholder=" " class="form-control" formControlName="childrenAgeComment" id="childrenAgeComment"></textarea>
                                        <label for="childrenAgeComment">{{ 'quotation.composition.comment.description.value' | translate }}</label>
                                        <span class="message-help"><mat-icon class="tooltip_icon">info</mat-icon>{{ 'form.validation.error.maxLength' | translate:{ value: maxLengthComment } }}</span>
                                        <mat-error *ngIf="compositionControl.get('childrenAgeComment').errors && (compositionControl.get('childrenAgeComment').dirty || compositionControl.get('childrenAgeComment').touched)">
                                            <div class="invalid-feedback">
                                                <div *ngIf="compositionControl.get('childrenAgeComment').errors.maxlength">{{ 'form.control.error.maxLength.value' | translate: { maxLength : maxLengthComment } }}</div>
                                            </div>
                                        </mat-error>
                                    </div>
                                </div>

                            </div>

                        </ng-container>

                    </div>
                </mat-expansion-panel>
            </mat-accordion>

        </div>

        <div class="form_part">

            <mat-accordion class="gradient">
                <mat-expansion-panel [expanded]="false">
                    <mat-expansion-panel-header class="col_md_8">
                        <mat-panel-title>{{ 'quotation.form.budget' | translate }}</mat-panel-title>
                    </mat-expansion-panel-header>
                    <div>

                        <div class="row marges ligne_form">

                            <!-- Budget maximum de mon client -->

                            <div class="col_12 col_md_4">

                                <div class="form-group" formGroupName="pricing">
                                    <input type="text" placeholder=" " class="form-control" formControlName="budget" id="budget">
                                    <label class="required" for="budget">{{ 'client.mine.budget.max.value' | translate }}</label>
                                    <mat-error *ngIf="pricingControl.get('budget').errors && (pricingControl.get('budget').dirty || pricingControl.get('budget').touched)">
                                        <div class="invalid-feedback">
                                            <div *ngIf="pricingControl.get('budget').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                            <div *ngIf="pricingControl.get('budget').errors.pattern">{{ 'form.control.error.pattern.value' | translate }}</div>
                                        </div>
                                    </mat-error>
                                </div>

                            </div>

                            <!-- Devise -->

                            <div class="col_12 col_md_4">

                                <div class="form-group" formGroupName="pricing">
                                    <mat-select [compareWith]="compareById" class="form-control" formControlName="currency">
                                        <mat-option *ngFor="let currency of currencies$ | async" [value]="currency">
                                            {{ translationService.getFallbackTranslation(currency.translations).label }} ({{ currency.code }})
                                        </mat-option>
                                    </mat-select>
                                    <mat-label class="required">{{ 'currency.value' | translate }}</mat-label>
                                    <mat-error *ngIf="pricingControl.get('currency').errors && (pricingControl.get('currency').dirty || pricingControl.get('currency').touched)">
                                        <div class="invalid-feedback">
                                            <div *ngIf="pricingControl.get('currency').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                        </div>
                                    </mat-error>
                                </div>

                            </div>

                        </div>
                        <div class="row marges ligne_form">

                            <!-- Commentaire concernant le budget -->

                            <div class="col_12 col_md_8">
                                <div class="form-group" formGroupName="pricing">
                                    <textarea placeholder=" " class="form-control" formControlName="comment" id="pricingComment"></textarea>
                                    <label for="pricingComment">{{ 'quotation.pricing.comment.description.value' | translate }}</label>
                                    <span class="message-help"><mat-icon class="tooltip_icon">info</mat-icon>{{ 'form.validation.error.maxLength' | translate:{ value: maxLengthComment } }}</span>
                                    <mat-error *ngIf="pricingControl.get('comment').errors && (pricingControl.get('comment').dirty || pricingControl.get('comment').touched)">
                                        <div class="invalid-feedback">
                                            <div *ngIf="pricingControl.get('comment').errors.maxlength">{{ 'form.control.error.maxLength.value' | translate: { maxLength : maxLengthComment } }}</div>
                                        </div>
                                    </mat-error>
                                </div>
                            </div>

                        </div>

                    </div>
                </mat-expansion-panel>
            </mat-accordion>

        </div>

        <div class="form_part">

            <mat-accordion class="gradient">
                <mat-expansion-panel [expanded]="false">
                    <mat-expansion-panel-header class="col_md_8">
                        <mat-panel-title>{{ 'quotation.form.wish' | translate }}</mat-panel-title>
                    </mat-expansion-panel-header>
                    <div>

                        <div class="row marges ligne_form">

                            <!-- Hébergements -->

                            <div class="col_12 col_md_4">

                                <div class="form-group" formGroupName="configuration">
                                    <mat-select multiple [compareWith]="compareById" formControlName="accommodations">
                                        <mat-option #noneAccommodationOption [value]="{ id: null }" (click)="handleAccommodation(noneAccommodationOption)"><span>{{ 'accommodation.withoutPreference.value' | translate }}</span></mat-option>
                                        <mat-option #accommodationOption *ngFor="let attribute of getAttributeObservable( 'accommodations') | async;" [value]="attribute" (click)="handleAccommodation(accommodationOption)">
                                            {{ translationService.getFallbackTranslation(attribute.translations).label }}
                                        </mat-option>
                                    </mat-select>
                                    <mat-label class="required">{{ 'accommodation.plural.value' | translate }}</mat-label>
                                    <mat-error *ngIf="configurationControl.get('accommodations').invalid && (configurationControl.get('accommodations').dirty || configurationControl.get('accommodations').touched)">
                                        <div class="invalid-feedback">
                                            <div *ngIf="configurationControl.get('accommodations').errors.minLengthArray">{{ 'form.control.error.required.value' | translate }}</div>
                                        </div>
                                    </mat-error>
                                </div>

                            </div>

                            <!-- Commentaire concernant l'hébergement -->

                            <div class="col_12 col_md_4">
                                <div class="form-group" formGroupName="configuration">
                                    <textarea placeholder=" " class="form-control" formControlName="accommodationsComment" id="accommodationsComment"></textarea>
                                    <label for="accommodationsComment">{{ 'quotation.configuration.accommodationsComment.description.value' | translate }}</label>
                                    <span class="message-help"><mat-icon class="tooltip_icon">info</mat-icon>{{ 'form.validation.error.maxLength' | translate:{ value: maxLengthComment } }}</span>
                                    <mat-error *ngIf="configurationControl.get('accommodationsComment').errors && (configurationControl.get('accommodationsComment').dirty || configurationControl.get('accommodationsComment').touched)">
                                        <div class="invalid-feedback">
                                            <div *ngIf="configurationControl.get('accommodationsComment').errors.maxlength">{{ 'form.control.error.maxLength.value' | translate: { maxLength : maxLengthComment } }}</div>
                                        </div>
                                    </mat-error>
                                </div>
                            </div>

                        </div>

                        <div class="row marges ligne_form">

                            <!-- Restauration -->

                            <div class="col_12 col_md_4">

                                <div class="form-group" formGroupName="configuration">
                                    <mat-select [compareWith]="compareById" formControlName="restoration">
                                        <mat-option [value]="{ id: null }">{{ 'withoutPreference.value' | translate }}</mat-option>
                                        <mat-option *ngFor="let attribute of getAttributeObservable( 'restoration') | async;" [value]="attribute">
                                            {{ translationService.getFallbackTranslation(attribute.translations).label }}
                                        </mat-option>
                                    </mat-select>
                                    <mat-label class="required">{{ 'restoration.value' | translate }}</mat-label>
                                    <mat-error *ngIf="configurationControl.get('restoration').invalid && (configurationControl.get('restoration').dirty || configurationControl.get('restoration').touched)">
                                        <div class="invalid-feedback">
                                            <div *ngIf="configurationControl.get('restoration').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                        </div>
                                    </mat-error>
                                </div>

                            </div>

                            <!-- Commentaire concernant la restauration -->

                            <div class="col_12 col_md_4">
                                <div class="form-group" formGroupName="configuration">
                                    <textarea placeholder=" " class="form-control" formControlName="restorationComment" id="restorationComment"></textarea>
                                    <label for="restorationComment">{{ 'quotation.configuration.restorationComment.description.value' | translate }}</label>
                                    <span class="message-help"><mat-icon class="tooltip_icon">info</mat-icon>{{ 'form.validation.error.maxLength' | translate:{ value: maxLengthComment } }}</span>
                                    <mat-error *ngIf="configurationControl.get('restorationComment').errors && (configurationControl.get('restorationComment').dirty || configurationControl.get('restorationComment').touched)">
                                        <div class="invalid-feedback">
                                            <div *ngIf="configurationControl.get('restorationComment').errors.maxlength">{{ 'form.control.error.maxLength.value' | translate: { maxLength : maxLengthComment } }}</div>
                                        </div>
                                    </mat-error>
                                </div>
                            </div>
                        </div>

                        <div class="row marges ligne_form">

                            <!-- Activités payantes souhaitées -->

                            <div class="col_12 col_md_4">

                                <div class="form-group" formGroupName="configuration">
                                    <mat-select multiple [compareWith]="compareById" formControlName="activities">
                                        <mat-option *ngFor="let attribute of getAttributeObservable( 'activities') | async;" [value]="attribute">
                                            {{ translationService.getFallbackTranslation(attribute.translations).label }}
                                        </mat-option>
                                    </mat-select>
                                    <mat-label>{{ 'activity.plural.payable.wish.value' | translate }}</mat-label>
                                </div>

                            </div>
                        </div>

                    </div>
                </mat-expansion-panel>
            </mat-accordion>

        </div>

        <div class="form_part">

            <mat-accordion class="gradient">
                <mat-expansion-panel [expanded]="false">
                    <mat-expansion-panel-header class="col_md_8">
                        <mat-panel-title>{{ 'quotation.form.more' | translate }}</mat-panel-title>
                    </mat-expansion-panel-header>
                    <div>

                        <div class="row marges ligne_form">

                            <!-- Précisions sur le projet -->

                            <div class="col_12 col_md_8">
                                <div class="form-group" formGroupName="customer">
                                    <textarea placeholder=" " class="form-control" formControlName="comment" id="customerComment"></textarea>
                                    <label class="required" for="customerComment">{{ 'quotation.customer.comment.description.value' | translate }}</label>
                                    <span class="message-help"><mat-icon class="tooltip_icon">info</mat-icon>{{ 'form.validation.error.maxLength' | translate:{ value: maxLengthComment } }}</span>
                                    <mat-error *ngIf="customerControl.get('comment').errors && (customerControl.get('comment').dirty || customerControl.get('comment').touched)">
                                        <div class="invalid-feedback">
                                            <div *ngIf="customerControl.get('comment').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                            <div *ngIf="customerControl.get('comment').errors.maxlength">{{ 'form.control.error.maxLength.value' | translate: { maxLength : maxLengthComment } }}</div>
                                        </div>
                                    </mat-error>
                                </div>
                            </div>

                        </div>
                        <div class="row marges ligne_form">

                            <!-- Langue parlée par mon client -->

                            <div class="col_12 col_md_4">

                                <div class="form-group" formGroupName="customer">
                                    <mat-select formControlName="spokenLanguage">
                                        <mat-option *ngFor="let language of spokenLanguages" [value]="language">{{ ('locale.' + language + '.value') | translate }}</mat-option>
                                        <mat-option [value]="otherSpokenLanguagesIdentifier">{{ 'other.value' | translate }}</mat-option>
                                    </mat-select>
                                    <mat-label class="required">{{ 'client.mine.language.spoken.value' | translate }}</mat-label>
                                    <mat-error *ngIf="customerControl.get('spokenLanguage').errors && (customerControl.get('spokenLanguage').dirty || customerControl.get('spokenLanguage').touched)">
                                        <div class="invalid-feedback">
                                            <div *ngIf="customerControl.get('spokenLanguage').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                        </div>
                                    </mat-error>
                                </div>

                            </div>

                            <ng-container *ngIf="customerControl.get('spokenLanguage').value === otherSpokenLanguagesIdentifier">

                                <!-- Autre langue parlée -->

                                <div class="col_12 col_md_4">

                                    <div class="form-group" formGroupName="customer">
                                        <input type="text" value="" placeholder=" " class="form-control" formControlName="otherSpokenLanguages" id="otherSpokenLanguages">
                                        <label for="otherSpokenLanguages">{{ 'language.spoken.other.value' | translate }}</label>
                                        <mat-error *ngIf="customerControl.get('otherSpokenLanguages').invalid && (customerControl.get('otherSpokenLanguages').dirty || customerControl.get('otherSpokenLanguages').touched)">
                                            <div class="invalid-feedback">
                                                <div *ngIf="customerControl.get('otherSpokenLanguages').errors.isRequired">{{ 'form.control.error.required.value' | translate }}</div>
                                            </div>
                                        </mat-error>
                                    </div>
                                </div>

                            </ng-container>

                        </div>
                        <div class="row marges ligne_form">

                            <!-- Pays du client -->

                            <div class="col_12 col_md_4">

                                <div class="form-group" formGroupName="customer">

                                    <mat-select [compareWith]="compareById" class="form-control" formControlName="country">
                                        <mat-option *ngFor="let country of countries$ | async" [value]="country">
                                            {{ translationService.getFallbackTranslation(country.translations).name }}
                                        </mat-option>
                                    </mat-select>
                                    <mat-label class="required">{{ 'client.country.value' | translate }}</mat-label>
                                    <mat-error *ngIf="customerControl.get('country').invalid && (customerControl.get('country').dirty || customerControl.get('country').touched)">
                                        <div class="invalid-feedback">
                                            <div *ngIf="customerControl.get('country').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                        </div>
                                    </mat-error>
                                </div>

                            </div>
                        </div>

                        <div class="row marges ligne_form">

                            <!-- Date limite de réponse -->

                            <div class="col_12 col_md_4">

                                <div class="form-group">
                                    <input type="text" class="datetimepicker" matInput readonly [matDatepicker]="closedAtPicker" [max]="maxClosedAt" formControlName="closedAt" (click)="closedAtPicker.open()" id="closedAt" placeholder=" ">
                                    <label for="closedAt">{{ 'response.date.limit.value' | translate }}</label>
                                    <mat-datepicker #closedAtPicker></mat-datepicker>
                                    <span class="message-help"><mat-icon>info</mat-icon>{{ 'quotation.response.date.limit.help.value' | translate }}</span>
                                    <mat-error *ngIf="form.get('closedAt').errors && (form.get('closedAt').dirty || form.get('closedAt').touched)">
                                        <div class="invalid-feedback">
                                            <div *ngIf="form.get('closedAt').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                        </div>
                                    </mat-error>
                                </div>

                            </div>

                        </div>

                        <div class="row marges ligne_form">

                            <!-- Assurance -->

                            <div class="col_12 col_content_md">

                                <div class="form-group" formGroupName="configuration">
                                    <label>{{'quotation.insurance.value' | translate}}</label>
                                    <div class="radio">
                                        <input type="radio" [value]="true" formControlName="hasInsurance" class="form-control" id="hasInsuranceYes">
                                        <label for="hasInsuranceYes">{{'form.user.fields.yes.value' | translate}}</label>
                                    </div>
                                    <div class="radio">
                                        <input type="radio" [value]="false" formControlName="hasInsurance" class="form-control" id="hasInsuranceNo">
                                        <label for="hasInsuranceNo">{{'form.user.fields.no.value' | translate}}</label>
                                    </div>
                                </div>
                            </div>

                            <ng-container *ngIf="configurationControl.get('hasInsurance').value">

                                <!-- Type assurance -->

                                <div class="col_12 col_md_4">
                                    <div class="form-group align_insurance" formGroupName="configuration">
                                        <input type="text" value="" placeholder=" " class="form-control" formControlName="insuranceComment" id="insuranceComment">
                                        <label class="required" for="insuranceComment">{{ 'insurance.type.value' | translate }}</label>
                                        <mat-error *ngIf="configurationControl.get('insuranceComment').errors && (configurationControl.get('insuranceComment').dirty || configurationControl.get('insuranceComment').touched)">
                                            <div class="invalid-feedback">
                                                <div *ngIf="configurationControl.get('insuranceComment').errors.isRequired">{{ 'form.control.error.required.value' | translate }}</div>
                                            </div>
                                        </mat-error>
                                    </div>
                                </div>

                            </ng-container>

                        </div>

                    </div>
                </mat-expansion-panel>
            </mat-accordion>

        </div>

    </ng-container>

</ng-template>

<ng-template #summary>

    <div class="form_part limited">

        <!-- Créateurs d'offres sélectionnés -->

        <div class="bloc_recap lightgreen">

            <div class="ck-content">

                <p class="titre_h3 highlight">{{ 'offerCreator.plural.selected.value' | translate}}</p>

                <ul>
                    <li *ngFor="let offerCreator of selectedOfferCreators">{{ offerCreator.name }}</li>
                </ul>

            </div>

        </div>

    </div>

    <div class="form_part limited">

        <!-- Données générales -->

        <div class="ck-content">

            <p class="titre_h3 highlight">{{ 'quotation.value' | translate }}</p>

            <div class="row marges ligne_form">

                <!-- Cible de clientèle -->

                <div class="col_12 col_md_6" *ngIf="quotation.configuration.customerTypology">
                    <div class="bloc_recap">
                        <div class="ck-content">
                            <p class="titre_h4 highlight">{{ 'customer.target.value' | translate }}</p>
                            <p>{{ translationService.getFallbackTranslation(quotation.configuration.customerTypology.translations).name }}</p>
                        </div>
                    </div>
                </div>

                <!-- Format bon cadeau ? -->

                <div class="col_12 col_md_6">
                    <div class="bloc_recap">
                        <div class="ck-content">
                            <p class="titre_h4 highlight">{{ 'giftVoucher.format.question.value' | translate }}</p>
                            <p>{{ (quotation.configuration.allowGiftVoucher ? 'yes.value' : 'no.value') | translate }}</p>
                        </div>
                    </div>
                </div>

            </div>

            <div class="row marges ligne_form">

                <!-- Référence -->

                <div class="col_12 col_md_6" *ngIf="quotation.ownerReference">
                    <div class="bloc_recap">
                        <div class="ck-content">
                            <p class="titre_h4 highlight">{{ 'reference.value' | translate }}</p>
                            <p>{{ quotation.ownerReference }}</p>
                        </div>
                    </div>
                </div>

            </div>

        </div>

    </div>

    <div class="form_part limited">

        <mat-accordion class="gradient">
            <mat-expansion-panel [expanded]="true">
                <mat-expansion-panel-header>
                    <mat-panel-title>{{ 'quotation.form.dates' | translate }}</mat-panel-title>
                </mat-expansion-panel-header>
                <div>

                    <div class="row marges ligne_form">

                        <div class="col_12">

                            <!-- Dates de séjour -->

                            <div class="bloc_recap" *ngIf="quotation.composition.dateStart && quotation.composition.dateEnd">
                                <div class="ck-content">
                                    <p class="titre_h4 highlight">{{ 'stay.date.plural.value' | translate }}</p>
                                    <p>{{ moment(quotation.composition.dateStart).format('DD/MM/YYYY') }} - {{ moment(quotation.composition.dateEnd).format('DD/MM/YYYY') }}</p>
                                </div>
                            </div>

                        </div>

                    </div>

                    <div class="row marges ligne_form">

                        <div class="col_12 col_md_6" *ngIf="quotation.composition.duration">

                            <!-- Durée du séjour -->

                            <div class="bloc_recap" >
                                <div class="ck-content">
                                    <p class="titre_h4 highlight">{{ 'offer.booking.stay.duration.value' | translate }}</p>
                                    <p>{{ translationService.getFallbackTranslation(quotation.composition.duration.translations).label }}</p>
                                </div>
                            </div>

                        </div>

                        <div class="col_12 col_md_6" *ngIf="quotation.composition.flexibility">

                            <!-- Flexibilité dates -->

                            <div class="bloc_recap">
                                <div class="ck-content">
                                    <p class="titre_h4 highlight">{{ 'dates.plural.flexibility.value' | translate }}</p>
                                    <p>{{ translationService.getFallbackTranslation(quotation.composition.flexibility.translations).label }}</p>
                                </div>
                            </div>

                        </div>

                    </div>

                    <div class="row marges ligne_form">

                        <!-- Destinations -->

                        <div class="col_12" *ngIf="quotation.configuration.regions.length">

                            <div class="bloc_recap">
                                <div class="ck-content">
                                    <p class="titre_h4 highlight">{{ 'destination.plural.value' | translate }}</p>
                                    <p>{{ parseAttributes(quotation.configuration.regions) }}</p>
                                </div>
                            </div>

                        </div>

                    </div>

                    <div class="row marges ligne_form" *ngIf="quotation.configuration.regionComment">

                        <!-- Commentaire destination -->

                        <div class="col_12">

                            <div class="bloc_recap">
                                <div class="ck-content">
                                    <p class="titre_h4 highlight">{{ 'quotation.configuration.regionComment.description.value' | translate }}</p>
                                    <p>{{ quotation.configuration.accommodationsComment }}</p>
                                </div>
                            </div>

                        </div>

                    </div>

                </div>
            </mat-expansion-panel>
        </mat-accordion>

    </div>

    <div class="form_part limited">

        <mat-accordion class="gradient">
            <mat-expansion-panel [expanded]="false">
                <mat-expansion-panel-header>
                    <mat-panel-title>{{ 'quotation.form.composition' | translate }}</mat-panel-title>
                </mat-expansion-panel-header>
                <div>

                    <div class="row marges ligne_form">

                        <!-- Nombre d'adulte(s) -->

                        <div class="col_12 col_md_6" *ngIf="quotation.composition.nbAdult">

                            <div class="bloc_recap">
                                <div class="ck-content">
                                    <p class="titre_h4 highlight">{{ 'adult.count.value' | translate }}</p>
                                    <p>{{ quotation.composition.nbAdult }}</p>
                                </div>
                            </div>

                        </div>

                        <!-- Nombre d'enfant(s) -->

                        <div class="col_12 col_md_6" *ngIf="quotation.composition.nbChild">

                            <div class="bloc_recap">
                                <div class="ck-content">
                                    <p class="titre_h4 highlight">{{ 'child.count.value' | translate }}</p>
                                    <p>{{ quotation.composition.nbChild }}</p>
                                </div>
                            </div>

                        </div>

                    </div>
                    <div class="row marges ligne_form">

                        <!-- Commentaire concernant les enfants -->

                        <div class="col_12 col_md_12" *ngIf="quotation.composition.childrenAgeComment">

                            <div class="bloc_recap">
                                <div class="ck-content">
                                    <p class="titre_h4 highlight">{{ 'quotation.composition.comment.description.value' | translate }}</p>
                                    <p>{{ quotation.composition.childrenAgeComment }}</p>
                                </div>
                            </div>

                        </div>

                    </div>

                </div>
            </mat-expansion-panel>
        </mat-accordion>

    </div>

    <div class="form_part limited">

        <mat-accordion class="gradient">
            <mat-expansion-panel [expanded]="false">
                <mat-expansion-panel-header>
                    <mat-panel-title>{{ 'quotation.form.budget' | translate }}</mat-panel-title>
                </mat-expansion-panel-header>
                <div>

                    <div class="row marges ligne_form" *ngIf="quotation.pricing.budget">

                        <!-- Budget maximum de mon client -->

                        <div class="col_12">

                            <div class="bloc_recap">
                                <div class="ck-content">
                                    <p class="titre_h4 highlight">{{ 'client.mine.budget.max.value' | translate }}</p>
                                    <p>{{ quotation.pricing.budget }} {{ translationService.getFallbackTranslation(quotation.pricing.currency.translations).label }} ({{ quotation.pricing.currency.symbol }})</p>
                                </div>
                            </div>

                        </div>

                    </div>

                    <div class="row marges ligne_form" *ngIf="quotation.pricing.comment">

                        <!-- Commentaire concernant le budget de mon client -->

                        <div class="col_12">

                            <div class="bloc_recap">
                                <div class="ck-content">
                                    <p class="titre_h4 highlight">{{ 'quotation.pricing.comment.description.value' | translate }}</p>
                                    <p>{{ quotation.pricing.comment }}</p>
                                </div>
                            </div>

                        </div>

                    </div>

                </div>
            </mat-expansion-panel>
        </mat-accordion>

    </div>

    <div class="form_part limited">

        <mat-accordion class="gradient">
            <mat-expansion-panel [expanded]="false">
                <mat-expansion-panel-header>
                    <mat-panel-title>{{ 'quotation.form.wish' | translate }}</mat-panel-title>
                </mat-expansion-panel-header>
                <div>

                    <div class="row marges ligne_form" *ngIf="quotation.configuration.accommodations.length">

                        <!-- Hébergements -->

                        <div class="col_12">

                            <div class="bloc_recap">
                                <div class="ck-content">
                                    <p class="titre_h4 highlight">{{ 'accommodation.plural.value' | translate }}</p>
                                    <p>{{ parseAttributes(quotation.configuration.accommodations) }}</p>
                                </div>
                            </div>

                        </div>

                    </div>

                    <div class="row marges ligne_form" *ngIf="quotation.configuration.accommodationsComment">

                        <!-- Commentaire hébergement (type de chambre, niveau de confort, situation…) -->

                        <div class="col_12">

                            <div class="bloc_recap">
                                <div class="ck-content">
                                    <p class="titre_h4 highlight">{{ 'quotation.configuration.accommodationsComment.description.value' | translate }}</p>
                                    <p>{{ quotation.configuration.accommodationsComment }}</p>
                                </div>
                            </div>

                        </div>

                    </div>

                    <!-- Restauration -->

                    <div class="row marges ligne_form" *ngIf="quotation.configuration.restoration">

                        <div class="col_12">

                            <div class="bloc_recap">
                                <div class="ck-content">
                                    <p class="titre_h4 highlight">{{ 'restoration.value' | translate }}</p>
                                    <p *ngIf="quotation.configuration.restoration.id">{{ translationService.getFallbackTranslation(quotation.configuration.restoration.translations).label }}</p>
                                    <p *ngIf="!quotation.configuration.restoration.id">{{ 'withoutPreference.value' | translate }}</p>
                                </div>
                            </div>

                        </div>

                    </div>

                    <div class="row marges ligne_form" *ngIf="quotation.configuration.restorationComment">

                        <!-- Commentaire restauration -->

                        <div class="col_12">

                            <div class="bloc_recap">
                                <div class="ck-content">
                                    <p class="titre_h4 highlight">{{ 'quotation.configuration.restorationComment.description.value' | translate }}</p>
                                    <p>{{ quotation.configuration.restorationComment }}</p>
                                </div>
                            </div>

                        </div>

                    </div>

                    <!-- Activités payantes souhaitées -->

                    <div class="row marges ligne_form" *ngIf="quotation.configuration.activities.length">

                        <div class="col_12">

                            <div class="bloc_recap">
                                <div class="ck-content">
                                    <p class="titre_h4 highlight">{{ 'activity.plural.payable.wish.value' | translate }}</p>
                                    <p>{{ parseAttributes(quotation.configuration.activities) }}</p>
                                </div>
                            </div>

                        </div>

                    </div>

                </div>
            </mat-expansion-panel>
        </mat-accordion>

    </div>

    <div class="form_part limited">

        <mat-accordion class="gradient">
            <mat-expansion-panel [expanded]="false">
                <mat-expansion-panel-header>
                    <mat-panel-title>{{ 'quotation.form.more' | translate }}</mat-panel-title>
                </mat-expansion-panel-header>
                <div>

                    <!-- Précisions sur le projet -->

                    <div class="row marges ligne_form" *ngIf="quotation.customer.comment">

                        <div class="col_12">

                            <div class="bloc_recap">
                                <div class="ck-content">
                                    <p class="titre_h4 highlight">{{ 'quotation.customer.comment.description.value' | translate }}</p>
                                    <p>{{ quotation.customer.comment }}</p>
                                </div>
                            </div>

                        </div>

                    </div>

                    <div class="row marges ligne_form">

                        <!-- Langue parlée par mon client -->

                        <div class="col_12 col_md_6" *ngIf="quotation.customer.spokenLanguage">

                            <div class="bloc_recap">
                                <div class="ck-content">
                                    <p class="titre_h4 highlight">{{ 'client.mine.language.spoken.value' | translate }}</p>
                                    <p>{{ 'locale.' + quotation.customer.spokenLanguage + '.value' | translate }}</p>
                                    <p *ngIf="quotation.customer.otherSpokenLanguages">{{ quotation.customer.otherSpokenLanguages }}</p>
                                </div>
                            </div>

                        </div>

                        <!-- Pays du client -->

                        <div class="col_12 col_md_6" *ngIf="quotation.customer.country">

                            <div class="bloc_recap">
                                <div class="ck-content">
                                    <p class="titre_h4 highlight">{{ 'client.country.value' | translate }}</p>
                                    <p>{{ translationService.getFallbackTranslation(quotation.customer.country.translations).name }}</p>
                                </div>
                            </div>

                        </div>

                    </div>

                    <!-- Date limite de réponse -->

                    <div class="row marges ligne_form" *ngIf="quotation.closedAt">

                        <div class="col_12">

                            <div class="bloc_recap">
                                <div class="ck-content">
                                    <p class="titre_h4 highlight">{{ 'response.date.limit.value' | translate }}</p>
                                    <p>{{ moment(quotation.closedAt).format('DD/MM/YYYY') }}</p>
                                </div>
                            </div>

                        </div>

                    </div>

                    <div class="row marges ligne_form">

                        <!-- Assurance -->

                        <div class="col_12" [ngClass]="{ 'col_md_6': quotation.configuration.hasInsurance }">

                            <div class="bloc_recap">
                                <div class="ck-content">
                                    <p class="titre_h4 highlight">{{ 'insurance.travel.value' | translate }}</p>
                                    <p>{{ (quotation.configuration.hasInsurance ? 'yes.value' : 'no.value') | translate }}</p>
                                </div>
                            </div>

                        </div>

                        <!-- Type assurance -->

                        <div class="col_12 col_md_6" *ngIf="quotation.configuration.hasInsurance">

                            <div class="bloc_recap">
                                <div class="ck-content">
                                    <p class="titre_h4 highlight">{{ 'insurance.type.value' | translate }}</p>
                                    <p> {{ quotation.configuration.insuranceComment ? quotation.configuration.insuranceComment : ('uninformed.value' | translate) }}</p>
                                </div>
                            </div>

                        </div>

                    </div>

                </div>
            </mat-expansion-panel>
        </mat-accordion>

    </div>

</ng-template>

<ng-template #confirmationTemplate>

    <div class="page_offer">

        <div class="txt_offer confirmation">

            <p class="title">{{ 'quotation.creation.success.title.value' | translate }}</p>

            <p>{{ 'quotation.creation.success.description.value' | translate }}</p>

            <p>{{ 'quotation.creation.success.redirection.description.value' | translate }}</p>

        </div>

        <div class="col_content x_center">

            <button class="btn_cta btn_lines btn_little" type="button" (click)="redirectToList()">
                {{ 'quotation.creation.success.button' | translate }}
            </button>
        </div>

    </div>

</ng-template>
