<form [formGroup]="form" (ngSubmit)="formService.submit()">

    <div class="page_title">

        <div class="row marges">

            <div class="col y_items_center">
                <h1 class="title">{{ 'quotation.read.value' | translate }}</h1>
            </div>

            <div class="col_content y_items_center">

                <!-- Retour à la liste des suivis de commande -->

                <button class="btn_cta btn_lines btn_little" type="button" (click)="redirectToList()">
                    {{ 'quotation.list.back.value' | translate }}
                </button>

                <ng-container *ngIf="updateAllowed">

                    <!-- Soumission -->

                    <button class="btn_cta btn_little" type="submit">
                        {{ 'form.submit.action.value'|translate }}
                    </button>

                </ng-container>

            </div>

        </div>

    </div>

    <!-- Gestion des onglets -->

    <mat-tab-group>
        <mat-tab [label]="'generalData.value' | translate">
            <ng-container *ngTemplateOutlet="generalData"></ng-container>
        </mat-tab>
        <mat-tab [label]="'quotation.data.plural.value' | translate">
            <ng-container *ngTemplateOutlet="quotationData"></ng-container>
        </mat-tab>
        <mat-tab [label]="'offerCreator.coordinate.plural.value' | translate" *ngIf="displayOfferCreators">
            <ng-container *ngTemplateOutlet="offerCreatorCoordinates"></ng-container>
        </mat-tab>
    </mat-tab-group>

</form>

<ng-template #generalData>

    <ng-container [formGroup]="form">

        <ng-container *ngIf="userService.hasRole(currentUser, 'ROLE_OFFER_CREATOR') && !isMine">

            <!-- Statut créateur d'offres -->

            <div class="ligne_form row marges">

                <div class="col_12 form-group">

                    <div class="framed_green">

                        <p class="read">
                            <strong class="subtitle_green">{{ 'offerCreator.status.value' | translate }}</strong>
                        </p>

                        <br/>

                        <div class="form-group">

                            <div class="radio">
                                <input type="radio" [value]="'reply'" formControlName="offerCreatorStatus" class="form-control" id="offerCreatorStatusReply">
                                <label for="offerCreatorStatusReply">{{ 'status.reply.action.mine.value' | translate }}</label>
                            </div>

                            <div class="radio">
                                <input type="radio" [value]="'no-reply'" formControlName="offerCreatorStatus" class="form-control" id="offerCreatorStatusNoReply">
                                <label for="offerCreatorStatusNoReply">{{ 'status.no-reply.action.mine.value' | translate }}</label>
                            </div>

                        </div>

                    </div>
                </div>
            </div>

            <ng-container *ngIf="form.get('offerCreatorStatus').value === 'no-reply'">

                <div class="ligne_form row marges">

                    <div class="col_6">

                        <div class="form-group">

                            <div class="form-group">
                                <input type="text" value="" placeholder=" " class="form-control" formControlName="offerCreatorNoReplyReason" id="offerCreatorNoReplyReason">
                                <label for="offerCreatorNoReplyReason">{{ 'noReply.reason.value' | translate }}</label>
                                <span class="message-help"><mat-icon class="tooltip_icon">info</mat-icon>{{ 'quotation.noReply.reason.help.value' | translate }}</span>
                                <mat-error *ngIf="form.get('offerCreatorNoReplyReason').errors && (form.get('offerCreatorNoReplyReason').dirty || form.get('offerCreatorNoReplyReason').touched)">
                                    <div class="invalid-feedback">
                                        <div *ngIf="form.get('offerCreatorNoReplyReason').errors.isRequired">{{ 'form.control.error.required.value' | translate }}</div>
                                    </div>
                                </mat-error>
                            </div>

                        </div>
                    </div>
                </div>

            </ng-container>

            <!-- Collaborateur en charge de la demande -->

            <div class="ligne_form row_marges">

                <div class="col_12">

                    <div class="form-group">

                        <p class="read">
                            <strong class="subtitle_green">{{ 'quotation.offerCreator.responsibleUser.value' | translate }}</strong>
                        </p>

                        <div style="margin-top: 12px">
                            <app-core-select-search [configuration]="collaboratorSearchConfiguration" [sourceCallback]="collaboratorSearchSourceCallback" [formGroupReference]="form" [formControlNameReference]="'offerCreatorResponsibleUser'"></app-core-select-search>
                        </div>

                    </div>
                </div>
            </div>

            <ng-container *ngIf="!!currentOfferCreatorResponsibleUser">

                <div class="ligne_form row marges">

                    <div class="col_12">

                        <div class="form-group">

                            <!-- Nom -->

                            <ng-container *ngIf="currentOfferCreatorResponsibleUser?.firstName || currentOfferCreatorResponsibleUser?.lastName">

                                <p class="read">
                                    <strong>{{ 'collaborator.name.value' | translate }} : </strong>
                                    {{ currentOfferCreatorResponsibleUser?.firstName }} {{ currentOfferCreatorResponsibleUser?.lastName }}
                                </p>

                            </ng-container>

                            <!-- Service -->

                            <ng-container *ngIf="currentOfferCreatorResponsibleUser?.service">

                                <p class="read">
                                    <strong>{{ 'collaborator.service.value' | translate }} : </strong>
                                    {{ currentOfferCreatorResponsibleUser?.service }}
                                </p>

                            </ng-container>

                            <!-- Email -->

                            <ng-container *ngIf="currentOfferCreatorResponsibleUser?.email">

                                <p class="read">
                                    <strong>{{ 'collaborator.email.value' | translate }} : </strong>
                                    {{ currentOfferCreatorResponsibleUser?.email }}
                                </p>

                            </ng-container>

                            <!-- Téléphone -->

                            <ng-container *ngIf="currentOfferCreatorResponsibleUser?.switchboardPhone">

                                <p class="read">
                                    <strong>{{ 'collaborator.phone.value' | translate }} : </strong>
                                    {{ currentOfferCreatorResponsibleUser?.switchboardPhone }}
                                </p>

                            </ng-container>

                            <!-- Adresse -->

                            <ng-container *ngIf="currentOfferCreatorResponsibleUser?.address">

                                <p class="read">
                                    <strong>{{ 'collaborator.address.value' | translate }} : </strong>
                                    {{ currentOfferCreatorResponsibleUser?.address.address }}, {{ currentOfferCreatorResponsibleUser?.address.zipcode }} {{ currentOfferCreatorResponsibleUser?.address.city }}
                                </p>

                            </ng-container>

                        </div>

                    </div>

                </div>

            </ng-container>

        </ng-container>

        <div class="ligne_form row marges col_12">

            <div class="col_12">

                <div class="form-group">

                    <!-- Référence -->

                    <p class="read">
                        <strong>{{ 'reference.value' | translate }} : </strong>
                        {{ quotation.ownerReference }}
                    </p>

                    <!-- N° de dossier -->

                    <p class="read">
                        <strong>{{ 'folder.numero.tywin.value' | translate }} : </strong>
                        <ng-container *ngIf="quotation.reference; else uninformed">
                            {{ quotation.reference }}
                        </ng-container>
                    </p>

                    <!-- Nom de l'émetteur -->

                    <p class="read">
                        <strong>{{ 'transmitter.name.value' | translate }} : </strong>
                        {{ quotation.society.name }}
                    </p>

                    <!-- Email de l'émetteur -->

                    <p class="read">
                        <strong>{{ 'transmitter.email.value' | translate }} : </strong>
                        <ng-container *ngIf="quotation.society.email; else uninformed">
                            {{ quotation.society.email }}
                        </ng-container>
                    </p>

                    <!-- Téléphone de l'émetteur -->

                    <p class="read">
                        <strong>{{ 'transmitter.phone.value' | translate }} : </strong>
                        <ng-container *ngIf="quotation.society.phone; else uninformed">
                            {{ quotation.society.phone }}
                        </ng-container>
                    </p>

                    <!-- Date de la demande -->

                    <p class="read">
                        <strong>{{ 'request.date.value' | translate }} : </strong>
                        {{ quotation.createdAt | date : 'dd/MM/yyyy' : null : localeId }}
                    </p>

                    <!-- Date limite de réponse -->

                    <p class="read">
                        <strong>{{ 'response.date.limit.value' | translate }} : </strong>
                        {{ quotation.closedAt | date : 'dd/MM/yyyy' : null : localeId }}
                    </p>

                </div>

            </div>

        </div>

        <!-- Statut appel d'offres -->

        <div class="ligne_form row marges">

            <div class="col_12">

                <div class="form-group">
                    <p class="read">
                        <strong class="subtitle_green">{{ 'quotation.status.value' | translate }}</strong>
                    </p>

                </div>
            </div>
        </div>

        <div class="ligne_form row marges">

            <div class="col_6">

                <div class="form-group">

                    <div class="radio">
                        <input type="radio" [value]="'in-progress'" formControlName="status" class="form-control" id="statusInProgress">
                        <label for="statusInProgress">{{ 'status.in-progress.value' | translate }}</label>
                    </div>

                    <div class="radio">
                        <input type="radio" [value]="'cancelled'" formControlName="status" class="form-control" id="statusCancelled">
                        <label for="statusCancelled">{{ 'status.cancelled.value' | translate }}</label>
                    </div>

                    <div class="radio">
                        <input type="radio" [value]="'finished'" formControlName="status" class="form-control" id="statusFinished">
                        <label for="statusFinished">{{ 'status.finished.value' | translate }}</label>
                    </div>

                </div>
            </div>

        </div>

        <!-- Collaborateur émetteur de la demande -->

        <div class="ligne_form row_marges">

            <div class="col_12">

                <div class="form-group">

                    <p class="read">
                        <strong class="subtitle_green">{{ 'request.collaborator.transmitter' | translate }}</strong>
                    </p>

                    <div style="margin-top: 12px">
                        <app-core-select-search [configuration]="collaboratorSearchConfiguration" [sourceCallback]="collaboratorSearchSourceCallback" [formGroupReference]="form" [formControlNameReference]="'responsibleUser'"></app-core-select-search>
                    </div>

                    <mat-error *ngIf="form.get('responsibleUser').invalid && (form.get('responsibleUser').dirty || form.get('responsibleUser').touched)">

                        <div class="invalid-feedback">

                            <div *ngIf="form.get('responsibleUser').errors.required">{{ 'form.control.error.required.value' | translate }}</div>

                        </div>

                    </mat-error>

                </div>
            </div>
        </div>

        <ng-container *ngIf="!!currentResponsibleUser">

            <div class="ligne_form row marges">

                <div class="col_12">

                    <div class="form-group">

                        <!-- Nom -->

                        <ng-container *ngIf="currentResponsibleUser?.firstName || currentResponsibleUser?.lastName">

                            <p class="read">
                                <strong>{{ 'collaborator.name.value' | translate }} : </strong>
                                {{ currentResponsibleUser?.firstName }} {{ currentResponsibleUser?.lastName }}
                            </p>

                        </ng-container>

                        <!-- Service -->

                        <ng-container *ngIf="currentResponsibleUser?.service">

                            <p class="read">
                                <strong>{{ 'collaborator.service.value' | translate }} : </strong>
                                {{ currentResponsibleUser?.service }}
                            </p>

                        </ng-container>

                        <!-- Email -->

                        <ng-container *ngIf="currentResponsibleUser?.email">

                            <p class="read">
                                <strong>{{ 'collaborator.email.value' | translate }} : </strong>
                                {{ currentResponsibleUser?.email }}
                            </p>

                        </ng-container>

                        <!-- Téléphone -->

                        <ng-container *ngIf="currentResponsibleUser?.switchboardPhone">

                            <p class="read">
                                <strong>{{ 'collaborator.phone.value' | translate }} : </strong>
                                {{ currentResponsibleUser?.switchboardPhone }}
                            </p>

                        </ng-container>

                        <!-- Adresse -->

                        <ng-container *ngIf="currentResponsibleUser?.address">

                            <p class="read">
                                <strong>{{ 'collaborator.address.value' | translate }} : </strong>
                                {{ currentResponsibleUser?.address.address }}, {{ currentResponsibleUser?.address.zipcode }} {{ currentResponsibleUser?.address.city }}
                            </p>

                        </ng-container>

                    </div>

                </div>

            </div>

        </ng-container>

    </ng-container>

</ng-template>

<ng-template #quotationData>

    <div class="ligne_form row marges col_12">

        <div class="col_12">

            <div class="form-group">

                <!-- Cible de clientèle -->

                <p class="read">
                    <strong>{{ 'customer.target.value' | translate }} : </strong>
                    <ng-container *ngIf="quotation.configuration.customerTypology; else uninformed">
                        {{ translationService.getFallbackTranslation(quotation.configuration.customerTypology.translations).name }}
                    </ng-container>
                </p>

                <!-- Format bon cadeau -->

                <p class="read">
                    <strong>{{ 'giftVoucher.format.question.value' | translate }} : </strong>
                    {{ (quotation.configuration.allowGiftVoucher ? 'yes.value' : 'no.value') | translate }}
                </p>

                <p class="read">
                    <br/>
                    <strong class="subtitle_green">{{ 'quotation.read.data.dates' | translate }}</strong>
                </p>

                <!-- Destinations -->

                <p class="read">
                    <strong>{{ 'destination.plural.value' | translate }} : </strong>
                    <ng-container *ngIf="quotation.configuration.regions.length; else uninformed">
                        {{ parsedAttributes(quotation.configuration.regions) }}
                    </ng-container>
                </p>


                <!-- Dates de séjour -->

                <p class="read">
                    <strong>{{ 'stay.date.plural.value' | translate }} : </strong>
                    <ng-container *ngIf="(quotation.composition.dateStart && quotation.composition.dateEnd); else uninformed">
                        {{ moment(quotation.composition.dateStart).format('DD/MM/YYYY') }} - {{ moment(quotation.composition.dateEnd).format('DD/MM/YYYY') }}
                    </ng-container>
                </p>

                <!-- Flexibilité dates -->

                <p class="read">
                    <strong>{{ 'dates.plural.flexibility.value' | translate }} : </strong>
                    <ng-container *ngIf="quotation.composition.flexibility; else uninformed">
                        {{ translationService.getFallbackTranslation(quotation.composition.flexibility.translations).label }}
                    </ng-container>
                </p>

                <!-- Durée du séjour -->

                <p class="read">
                    <strong>{{ 'offer.booking.stay.duration.value' | translate }} : </strong>
                    <ng-container *ngIf="quotation.composition.duration; else uninformed">
                        {{ translationService.getFallbackTranslation(quotation.composition.duration.translations).label }}
                    </ng-container>
                </p>

                <p class="read">
                    <br/>
                    <strong class="subtitle_green">{{ 'quotation.read.data.count' | translate }}</strong>
                </p>

                <!-- Nombre d'adulte(s) -->

                <p class="read">
                    <strong>{{ 'adult.count.value' | translate }} : </strong>
                    <ng-container *ngIf="quotation.composition.nbAdult; else uninformed">
                        {{ quotation.composition.nbAdult }}
                    </ng-container>
                </p>

                <!-- Nombre d'enfant(s) -->

                <p class="read">
                    <strong>{{ 'child.count.value' | translate }} : </strong>
                    <ng-container *ngIf="quotation.composition.nbChild; else uninformed">
                        {{ quotation.composition.nbChild }}
                    </ng-container>
                </p>

                <!-- Commentaire concernant les enfants (Âge de .. à .. ans) -->

                <p class="read">
                    <strong>{{ 'quotation.composition.comment.description.value' | translate }} : </strong>
                    <ng-container *ngIf="quotation.composition.childrenAgeComment; else uninformed">
                        {{ quotation.composition.childrenAgeComment }}
                    </ng-container>
                </p>

                <p class="read">
                    <br/>
                    <strong class="subtitle_green">{{ 'quotation.read.data.budget' | translate }}</strong>
                </p>

                <!-- Devise -->

                <p class="read">
                    <strong>{{ 'currency.value' | translate }} : </strong>
                    <ng-container *ngIf="quotation.pricing.currency; else uninformed">
                        {{ translationService.getFallbackTranslation(quotation.pricing.currency.translations).label }} ({{ quotation.pricing.currency.symbol }})
                    </ng-container>
                </p>

                <!-- Budget maximum de mon client -->

                <p class="read">
                    <strong>{{ 'client.mine.budget.max.value' | translate }} : </strong>
                    <ng-container *ngIf="(quotation.pricing.currency && quotation.pricing.budget); else uninformed">
                        {{ quotation.pricing.budget }} {{ quotation.pricing.currency.symbol }}
                    </ng-container>
                </p>

                <!-- Commentaire concernant le budget de mon client -->

                <p class="read">
                    <strong>{{ 'quotation.pricing.comment.description.value' | translate }} : </strong>
                    <ng-container *ngIf="quotation.pricing.comment; else uninformed">
                        {{ quotation.pricing.comment }}
                    </ng-container>
                </p>

                <p class="read">
                    <br/>
                    <strong class="subtitle_green">{{ 'quotation.read.data.wish' | translate }}</strong>
                </p>

                <!-- Hébergements -->

                <p class="read">
                    <strong>{{ 'accommodation.plural.value' | translate }} : </strong>
                    <ng-container *ngIf="quotation.configuration.accommodations.length; else uninformed">
                        {{ parsedAttributes(quotation.configuration.accommodations) }}
                    </ng-container>
                </p>

                <!-- Commentaire hébergement (type de chambre, niveau de confort, situation…) -->

                <p class="read">
                    <strong>{{ 'quotation.configuration.accommodationsComment.description.value' | translate }} : </strong>
                    <ng-container *ngIf="quotation.configuration.accommodationsComment; else uninformed">
                        {{ quotation.configuration.accommodationsComment }}
                    </ng-container>
                </p>

                <!-- Restauration -->

                <p class="read">
                    <strong>{{ 'restoration.value' | translate }} : </strong>
                    <ng-container *ngIf="quotation.configuration.restoration; else uninformed">
                        {{ translationService.getFallbackTranslation(quotation.configuration.restoration.translations).label }}
                    </ng-container>
                </p>

                <!-- Activités payantes souhaitées -->

                <p class="read">
                    <strong>{{ 'activity.plural.payable.wish.value' | translate }} : </strong>
                    <ng-container *ngIf="quotation.configuration.activities.length; else uninformed">
                        {{ parsedAttributes(quotation.configuration.activities) }}
                    </ng-container>
                </p>

                <p class="read">
                    <br/>
                    <strong class="subtitle_green">{{ 'quotation.read.data.more' | translate }}</strong>
                </p>

                <!-- Précisions sur le projet de mon client (moyen de transport, lieu de prise en charge, besoin d’un accompagnement…) -->

                <p class="read">
                    <strong>{{ 'quotation.customer.comment.description.value' | translate }} : </strong>
                    <ng-container *ngIf="quotation.customer.comment; else uninformed">
                        {{ quotation.customer.comment }}
                    </ng-container>
                </p>

                <!-- Langue parlée par mon client -->

                <p class="read">
                    <strong>{{ 'client.mine.language.spoken.value' | translate }} : </strong>
                    <ng-container *ngIf="quotation.customer.spokenLanguage; else uninformed">
                        {{ 'locale.' + quotation.customer.spokenLanguage + '.value' | translate }}
                    </ng-container>
                </p>

                <p class="read" *ngIf="(!quotation.customer.spokenLanguage && quotation.customer.otherSpokenLanguages)">
                    <strong>{{ 'client.mine.language.spoken.value' | translate }} : </strong>
                    {{ quotation.customer.otherSpokenLanguages }}
                </p>

                <!-- Pays de mon client -->

                <p class="read">
                    <strong>{{ 'client.country.value' | translate }} : </strong>
                    <ng-container *ngIf="(quotation.customer.country && getCountryByCode(quotation.customer.country)); else uninformed">
                        {{ translationService.getFallbackTranslation(getCountryByCode(quotation.customer.country).translations).name }}
                    </ng-container>
                </p>

                <!-- Assurance -->

                <p class="read">
                    <strong>{{ 'quotation.insurance.value' | translate }} : </strong>
                    {{ (quotation.configuration.hasInsurance ? 'yes.value' : 'no.value') | translate }}

                </p>

                <!-- Type assurance -->

                <p class="read" *ngIf="quotation.configuration.insuranceComment">
                    <strong>{{ 'insurance.type.value' | translate }} : </strong>
                    {{ quotation.configuration.insuranceComment }}
                </p>

            </div>

        </div>

    </div>

</ng-template>

<ng-template #offerCreatorCoordinates>

    <div class="ligne_form row marges">

        <div class="col_12">

            <div class="form-group">

                <div class="row marges">

                    <div class="col_content y_content_start" *ngFor="let offerCreator of quotation.offerCreators">

                        <div class="read_composition_option" style="width: 100%">

                            <p class="read">
                                <strong>{{ 'offerCreator.name.value' | translate }} : </strong>
                                {{ offerCreator.offerCreator.name }}
                            </p>

                            <p class="read">
                                <strong>{{ 'phone.value' | translate }} : </strong>
                                {{ offerCreator.offerCreator.phone }}
                            </p>

                            <p class="read">
                                <strong>{{ 'status.value' | translate }} : </strong>
                                {{ ('offerCreator.status.' + offerCreator.status + '.value' | translate) }}
                            </p>

                            <ng-container *ngIf="offerCreator.noReplyReason">

                                <p class="read">
                                    <strong>{{ 'noReply.reason.value' | translate }} : </strong>
                                    {{ offerCreator.noReplyReason }}
                                </p>

                            </ng-container>

                        </div>

                    </div>

                </div>

            </div>

        </div>

    </div>

</ng-template>

<ng-template #uninformed>{{ 'uninformed.value' | translate }}</ng-template>
