import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {PageDashboardComponent} from '@core/pages/page-dashboard/page-dashboard.component';
import {PageSigninComponent} from '@core/pages/page-signin/page-signin.component';
import {AuthenticationGuard} from '@core/shared/guards/authentication.guard';
import {PageAdministratorListComponent} from '@core/pages/page-administrator/page-administrator-list/page-administrator-list.component';
import {PageAdministratorCreateComponent} from '@core/pages/page-administrator/page-administrator-create/page-administrator-create.component';
import {PageAdministratorUpdateComponent} from '@core/pages/page-administrator/page-administrator-update/page-administrator-update.component';
import {UserItemResolver} from '@core/shared/resolvers/user-item-resolver';
import {PageUserValidateAccountComponent} from '@core/pages/page-user/page-user-validate-account/page-user-validate-account.component';
import {PageInvoiceListComponent} from '@core/pages/page-invoice/page-invoice-list/page-invoice-list.component';
import {PageSubscriptionListComponent} from '@core/pages/page-subscription/page-subscription-list/page-subscription-list.component';
import {PageUserListComponent} from '@core/pages/page-user/page-user-list/page-user-list.component';
import {PageUserCreateComponent} from '@core/pages/page-user/page-user-create/page-user-create.component';
import {PageUserUpdateComponent} from '@core/pages/page-user/page-user-update/page-user-update.component';
import {SocietyItemResolver} from '@core/shared/resolvers/society-item-resolver';
import {PageUserForgetPasswordComponent} from '@core/pages/page-user/page-user-forget-password/page-user-forget-password.component';
import {PageUserResetPasswordComponent} from '@core/pages/page-user/page-user-reset-password/page-user-reset-password.component';
import {PageUserAccountComponent} from '@core/pages/page-user/page-user-account/page-user-account.component';
import {UserSelfItemResolver} from '@core/shared/resolvers/user-self-item-resolver';
import {PageAccountLayoutComponent} from '@core/pages/page-account-layout/page-account-layout.component';
import {PageCollaboratorListComponent} from '@core/pages/page-collaborator/page-collaborator-list/page-collaborator-list.component';
import {AccessListResolver} from '@core/shared/resolvers/access-list-resolver';
import {PageOfferAttributeListComponent} from '@core/pages/page-offer/page-offer-attribute/page-offer-attribute-list/page-offer-attribute-list.component';
import {SocietyItemSelfResolver} from '@core/shared/resolvers/society-item-self-resolver';
import {PageCollaboratorCreateComponent} from '@core/pages/page-collaborator/page-collaborator-create/page-collaborator-create.component';
import {PageCollaboratorUpdateComponent} from '@core/pages/page-collaborator/page-collaborator-update/page-collaborator-update.component';
import {PageOfferAttributeCreateComponent} from '@core/pages/page-offer/page-offer-attribute/page-offer-attribute-create/page-offer-attribute-create.component';
import {PageOfferAttributeUpdateComponent} from '@core/pages/page-offer/page-offer-attribute/page-offer-attribute-update/page-offer-attribute-update.component';
import {OfferAttributeItemResolver} from '@core/shared/resolvers/offer-attribute-item-resolver';
import {OfferAttributeTypeListResolver} from '@core/shared/resolvers/offer-attribute-type-list-resolver';
import {ChannelItemResolver} from '@core/shared/resolvers/channel-item.resolver';
import {PageChannelRestrictedListComponent} from '@core/pages/page-channel/page-channel-restricted/page-channel-restricted-list/page-channel-restricted-list.component';
import {SocietyItemsResolver} from '@core/shared/resolvers/society-items-resolver';
import {PageLicenceUpdateComponent} from '@core/pages/page-subscription/page-subscription-licence/page-licence-update/page-licence-update.component';
import {PageOfferCreateComponent} from "@core/pages/page-offer/page-offer-create/page-offer-create.component";
import {PageOfferUpdateComponent} from "@core/pages/page-offer/page-offer-update/page-offer-update.component";
import {OfferItemResolver} from "@core/shared/resolvers/offer-item-resolver";
import {PageOfferListComponent} from "@core/pages/page-offer/page-offer-list/page-offer-list.component";
import {PageChannelCreateComponent} from "@core/pages/page-channel/page-channel-create/page-channel-create.component";
import {PageChannelListComponent} from "@core/pages/page-channel/page-channel-list/page-channel-list.component";
import {OfferDurationItemsResolver} from '@core/shared/resolvers/offer-duration-items.resolver';
import {PageChannelUpdateComponent} from "@core/pages/page-channel/page-channel-update/page-channel-update.component";
import {PageOfferReadComponent} from "@core/pages/page-offer/page-offer-read/page-offer-read.component";
import {PageDistributorApprovalListComponent} from "@core/pages/page-distributor/page-distributor-approval/page-distributor-approval-list/page-distributor-approval-list.component";
import {PageSocietyDistributorListComponent} from "@core/pages/page-society/page-society-distributor/page-society-distributor-list/page-society-distributor-list.component";
import {PageSocietyDistributorUpdateComponent} from "@core/pages/page-society/page-society-distributor/page-society-distributor-update/page-society-distributor-update.component";
import {SocietyDistributorItemResolver} from "@core/shared/resolvers/society/society-distributor-item-resolver";
import {MenuItemsResolver} from "@core/shared/resolvers/menu-items.resolver";
import {PageMenuCreateComponent} from "@core/pages/page-menu/page-menu-create/page-menu-create.component";
import {PageMenuUpdateComponent} from "@core/pages/page-menu/page-menu-update/page-menu-update.component";
import {PageMenuListComponent} from "@core/pages/page-menu/page-menu-list/page-menu-list.component";
import {PageCreateComponent} from "@core/pages/page/page-create/page-create.component";
import {PageUpdateComponent} from "@core/pages/page/page-update/page-update.component";
import {PageListComponent} from "@core/pages/page/page-list/page-list.component";
import {PageItemsResolver} from "@core/shared/resolvers/page-items.resolver";
import {PageItemResolver} from "@core/shared/resolvers/page-item.resolver";
import {PageCategoryListComponent} from "@core/pages/page-category/page-category-list/page-category-list.component";
import {PageCategoryUpdateComponent} from "@core/pages/page-category/page-category-update/page-category-update.component";
import {PageCategoryCreateComponent} from "@core/pages/page-category/page-category-create/page-category-create.component";
import {PageCategoryItemsResolver} from "@core/shared/resolvers/page-category-items.resolver";
import {PageCategoryItemResolver} from "@core/shared/resolvers/page-category-item.resolver";
import {MenuItemResolver} from "@core/shared/resolvers/menu-item.resolver";
import {PageCategoryReadComponent} from "@core/pages/page-category/page-category-read/page-category-read.component";
import {PageReadComponent} from "@core/pages/page/page-read/page-read.component";
import {PageOfferMarkupListComponent} from "@core/pages/page-offer/page-offer-markup/page-offer-markup-list/page-offer-markup-list.component";
import {PageOfferNetworkProviderItemResolver} from "@core/shared/resolvers/page-offer-network-provider-item.resolver";
import {PageOfferNetworkProviderItemsResolver} from "@core/shared/resolvers/page-offer-network-provider-items.resolver";
import {PageOfferGroupNetworkProviderListComponent} from "@core/pages/page-offer/page-offer-group-network-provider/page-offer-group-network-provider-list/page-offer-group-network-provider-list.component";
import {PageOfferGroupNetworkProviderCreateComponent} from "@core/pages/page-offer/page-offer-group-network-provider/page-offer-group-network-provider-create/page-offer-group-network-provider-create.component";
import {PageOfferGroupNetworkProviderUpdateComponent} from "@core/pages/page-offer/page-offer-group-network-provider/page-offer-group-network-provider-update/page-offer-group-network-provider-update.component";
import {ChannelItemsResolver} from "@core/shared/resolvers/channel-items.resolver";
import {PageBookingItemsResolver} from "@core/shared/resolvers/page-booking-items.resolver";
import {PageBookingListComponent} from "@core/pages/page-booking/page-booking-list/page-booking-list.component";
import {PageBookingReadComponent} from "@core/pages/page-booking/page-booking-read/page-booking-read.component";
import {PageBookingItemResolver} from "@core/shared/resolvers/page-booking-item.resolver";
import {PageOfferMarkupCatalogListComponent} from "@core/pages/page-offer/page-offer-markup-catalog/page-offer-markup-catalog-list/page-offer-markup-catalog-list.component";
import {PagePromotionListComponent} from "@core/pages/page-promotion/page-promotion-list/page-promotion-list.component";
import {PagePromotionUpdateComponent} from "@core/pages/page-promotion/page-promotion-update/page-promotion-update.component";
import {PagePromotionCreateComponent} from "@core/pages/page-promotion/page-promotion-create/page-promotion-create.component";
import {AccessManageListResolver} from "@core/shared/resolvers/access-manage-list-resolver";
import {AccessGuard} from "@core/shared/guards/access.guard";
import {PromotionItemResolver} from "@core/shared/resolvers/promotion-item.resolver";
import {PageOfferOptionListComponent} from "@core/pages/page-offer/page-offer-option/page-offer-option-list/page-offer-option-list.component";
import {PageOfferOptionCreateComponent} from "@core/pages/page-offer/page-offer-option/page-offer-option-create/page-offer-option-create.component";
import {PageOfferOptionUpdateComponent} from "@core/pages/page-offer/page-offer-option/page-offer-option-update/page-offer-option-update.component";
import {OfferOptionItemResolver} from "@core/shared/resolvers/offer-option-item.resolver";
import {PageOfferPermanentOptionListComponent} from "@core/pages/page-offer/page-offer-permanent-option/page-offer-permanent-option-list/page-offer-permanent-option-list.component";
import {OfferPermanentOptionResolver} from "@core/shared/resolvers/offer-permanent-option.resolver";
import {SubscriptionItemsResolver} from "@core/shared/resolvers/subscription-items.resolver";
import {SubscriptionSocietyItemsResolver} from "@core/shared/resolvers/subscription-society.items.resolver";
import {PageSubscriptionPaymentComponent} from "@core/pages/page-subscription/page-subscription-payment/page-subscription-payment.component";
import {PageSubscriptionPaymentResponseComponent} from "@core/pages/page-subscription/page-subscription-payment-response/page-subscription-payment-response.component";
import {SubscriptionGuard} from "@core/shared/guards/subscription.guard";
import {PagePromotionSubscriptionListComponent} from "@core/pages/page-promotion-subscription/page-promotion-subscription-list/page-promotion-subscription-list.component";
import {PagePromotionSubscriptionCreateComponent} from "@core/pages/page-promotion-subscription/page-promotion-subscription-create/page-promotion-subscription-create.component";
import {PromotionSubscriptionItemResolver} from "@core/shared/resolvers/promotion-subscription-item.resolver";
import {PagePromotionSubscriptionReadComponent} from "@core/pages/page-promotion-subscription/page-promotion-read/page-promotion-subscription-read.component";
import {PageReferenceReadComponent} from "@core/pages/page-reference/page-reference-read/page-reference-read.component";
import {PageSocietyProviderListComponent} from "@core/pages/page-society/page-society-provider/page-society-provider-list/page-society-provider-list.component";
import {PageSocietyProviderReadComponent} from "@core/pages/page-society/page-society-provider/page-society-provider-read/page-society-provider-read.component";
import {SocietyProviderItemResolver} from "@core/shared/resolvers/society/society-provider-item.resolver";
import {PageChannelReadComponent} from "@core/pages/page-channel/page-channel-read/page-channel-read.component";
import {SocietyProviderItemsResolver} from "@core/shared/resolvers/society-provider-items-resolver";
import {PageServiceListComponent} from "@core/pages/page-service/page-service-list/page-service-list.component";
import {PagePromotionServiceListComponent} from "@core/pages/page-promotion-service/page-promotion-service-list/page-promotion-service-list.component";
import { ServiceSocietyItemsResolver } from "@core/shared/resolvers/service-society-items.resolver";
import {PageServiceSubscriptionCreateComponent} from "@core/pages/page-service/page-service-subscription-create/page-service-subscription-create.component";
import {PageServiceSubscriptionUpdateComponent} from "@core/pages/page-service/page-service-subscription-update/page-service-subscription-update.component";
import {PagePromotionServiceCreateComponent} from "@core/pages/page-promotion-service/page-promotion-service-create/page-promotion-service-create.component";
import {AccessType} from "@core/shared/models/access";
import {ServiceItemsResolver} from "@core/shared/resolvers/service-items.resolver";
import {PagePromotionServiceUpdateComponent} from "@core/pages/page-promotion-service/page-promotion-service-update/page-promotion-service-update.component";
import {PromotionServiceItemResolver} from "@core/shared/resolvers/promotion-service-item.resolver";
import { PageServiceSubscriptionPaymentResponseComponent } from "@core/pages/page-service/page-service-subscription-payment-response/page-service-subscription-payment-response.component";
import {PageCardCreateComponent} from "@core/pages/page-card/page-card-create/page-card-create.component";
import {PageCardUpdateComponent} from "@core/pages/page-card/page-card-update/page-card-update.component";
import {PageCardPaymentResponseComponent} from "@core/pages/page-card/page-card-payment/page-card-payment-response/page-card-payment-response.component";
import {SocietyServiceItemResolver} from "@core/shared/resolvers/society/society-service/society-service-item-resolver";
import {ServiceItemResolver} from "@core/shared/resolvers/service-item-resolver";
import { PageServiceGiftVoucherComponent } from "@core/pages/page-service/page-service-gift-voucher-create/page-service-gift-voucher.component";
import {ServiceGuard} from "@core/shared/guards/service.guard";
import { PageTransporterListComponent } from "@core/pages/page-transporter/page-transporter-list/page-transporter-list.component";
import { PageTransporterCreateComponent } from "@core/pages/page-transporter/page-transporter-create/page-transporter-create.component";
import { PageTransporterUpdateComponent } from "@core/pages/page-transporter/page-transporter-update/page-transporter-update.component";
import {TransporterItemResolver} from "@core/shared/resolvers/transporter-item-resolver";
import { PageTemplateGiftVoucherListComponent } from "@core/pages/page-template-gift-voucher/page-template-gift-voucher-list/page-template-gift-voucher-list.component";
import { PageTemplateGiftVoucherCreateComponent } from "@core/pages/page-template-gift-voucher/page-template-gift-voucher-create/page-template-gift-voucher-create.component";
import { PageTemplateGiftVoucherUpdateComponent } from "@core/pages/page-template-gift-voucher/page-template-gift-voucher-update/page-template-gift-voucher-update.component";
import {TemplateGiftVoucherResolver} from "@core/shared/resolvers/template-gift-voucher.resolver";
import {PageGiftVoucherItemResolver} from "@core/shared/resolvers/page-gift-voucher-item.resolver";
import {PageGiftVoucherItemsResolver} from "@core/shared/resolvers/page-gift-voucher-items.resolver";
import {PageGiftVoucherListComponent} from "@core/pages/page-gift-voucher/page-gift-voucher-list/page-gift-voucher-list.component";
import { PageGiftVoucherReadComponent } from "@core/pages/page-gift-voucher/page-gift-voucher-read/page-gift-voucher-read.component";
import {PageGiftVoucherPeriodComponent} from "@core/pages/page-gift-voucher/page-gift-voucher-period/page-gift-voucher-period.component";
import {PageCardReadComponent} from "@core/pages/page-card/page-card-read/page-card-read.component";
import {ChannelMode} from "@core/shared/models/channel";
import {PageAvailabilityOfferListComponent} from "@core/pages/page-availability/page-availability-offer/page-availability-offer-list/page-availability-offer-list.component";
import {PageAvailabilityOfferReadComponent} from "@core/pages/page-availability/page-availability-offer/page-availability-offer-read/page-availability-offer-read.component";
import {PageAvailabilityOfferUpdateComponent} from "@core/pages/page-availability/page-availability-offer/page-availability-offer-update/page-availability-offer-update.component";
import {PageNetworkOfferCreatorListComponent} from "@core/pages/page-network/page-network-offer-creator/page-network-offer-creator-list/page-network-offer-creator-list.component";
import {PageNetworkOfferCreatorCreateComponent} from "@core/pages/page-network/page-network-offer-creator/page-network-offer-creator-create/page-network-offer-creator-create.component";
import {PageNetworkOfferCreatorUpdateComponent} from "@core/pages/page-network/page-network-offer-creator/page-network-offer-creator-update/page-network-offer-creator-update.component";
import {NetworkOfferCreatorItemResolver} from "@core/shared/resolvers/network-offer-creator-item.resolver";
import {CrudAction} from "@core/shared/models/crud";
import {PageOfferPermanentOptionFormComponent} from "@core/pages/page-offer/page-offer-permanent-option/page-offer-permanent-option-form/page-offer-permanent-option-form.component";
import {PageRegistrationAffiliationListComponent} from "@core/pages/page-registration-affiliation/page-registration-affiliation-list/page-registration-affiliation-list.component";
import {PageRegistrationAffiliationCreateComponent} from "@core/pages/page-registration-affiliation/page-registration-affiliation-create/page-registration-affiliation-create.component";
import {PageRegistrationAffiliationUpdateComponent} from "@core/pages/page-registration-affiliation/page-registration-affiliation-update/page-registration-affiliation-update.component";
import {RegistrationAffiliationItemResolver} from "@core/shared/resolvers/registration-affiliation-item.resolver";
import {PageOfferCreatorListComponent} from "@core/pages/page-offer/page-offer-creator/page-offer-creator-list/page-offer-creator-list.component";
import {PageOfferCreatorReadComponent} from "@core/pages/page-offer/page-offer-creator/page-offer-creator-read/page-offer-creator-read.component";
import {OfferCreatorItemResolver} from "@core/shared/resolvers/offer-creator-item.resolver";
import {PageQuotationCreateComponent} from "@core/pages/page-quotation/page-quotation-create/page-quotation-create.component";
import {PageQuotationListComponent} from "@core/pages/page-quotation/page-quotation-list/page-quotation-list.component";
import {PageQuotationReadComponent} from "@core/pages/page-quotation/page-quotation-read/page-quotation-read.component";
import {PageQuotationItemResolver} from "@core/shared/resolvers/page-quotation-item.resolver";
import {SocietyOfferCreatorItemResolver} from "@core/shared/resolvers/society-offer-creator-item-resolver";

const routes: Routes = [
    {
        path: '',
        redirectTo: 'signin',
        pathMatch: 'full'
    },
    {
        path: 'signin',
        component: PageSigninComponent
    },
    {
        path: 'validate-account/:token',
        component: PageUserValidateAccountComponent
    },
    {
        path: 'forget-password',
        component: PageUserForgetPasswordComponent
    },
    {
        path: 'reset-password/:token',
        component: PageUserResetPasswordComponent
    },
    {
        path: 'account',
        component: PageAccountLayoutComponent,
        runGuardsAndResolvers: 'always',
        children: [
            {
                path: '',
                pathMatch: 'full',
                component: PageUserAccountComponent,
                runGuardsAndResolvers: 'always',
                resolve: {
                    user: UserSelfItemResolver
                }
            },
            {
                path: 'dashboard',
                component: PageDashboardComponent,
                runGuardsAndResolvers: 'always',
                resolve: {
                    user: UserSelfItemResolver
                }
            },
            {
                path: 'administrator',
                children: [
                    {
                        path: '',
                        redirectTo: 'list',
                        pathMatch: 'full'
                    },
                    {
                        path: 'list',
                        component: PageAdministratorListComponent
                    },
                    {
                        path: 'create',
                        component: PageAdministratorCreateComponent
                    },
                    {
                        path: 'update/:id',
                        component: PageAdministratorUpdateComponent,
                        resolve: {
                            user: UserItemResolver
                        }
                    }
                ]
            },
            {
                path: 'user',
                children: [
                    {
                        path: 'list',
                        component: PageUserListComponent
                    },
                    {
                        path: 'create',
                        component: PageUserCreateComponent
                    },
                    {
                        path: 'update/:id',
                        component: PageUserUpdateComponent,
                        resolve: {
                            society: SocietyItemResolver,
                            accesses: AccessListResolver
                        }
                    }
                ]
            },
            {
                path: 'subscription',
                children: [
                    {
                        path: 'licence',
                        children: [
                            {
                                path: 'update',
                                component: PageLicenceUpdateComponent,
                                resolve: {
                                    subscriptions: SubscriptionItemsResolver,
                                    societySubscriptions: SubscriptionSocietyItemsResolver,
                                },
                            }
                        ]
                    },
                    {
                        path: 'list',
                        component: PageSubscriptionListComponent,
                        resolve: {
                            subscriptions: SubscriptionItemsResolver,
                            societySubscriptions: SubscriptionSocietyItemsResolver,
                        },
                        canActivate: [AccessGuard],
                        data: {
                            accessRequired: ['SOCIETY_SUBSCRIPTION_CREATE_IS_MINE']
                        },
                        runGuardsAndResolvers: 'always'
                    },
                    {
                        path: 'payment',
                        component: PageSubscriptionPaymentComponent,
                        canActivate: [AccessGuard],
                        data: {
                            accessRequired: ['PAYMENT_SOCIETY_IS_MINE']
                        }
                    },
                    {
                        path: 'payment-response/:response',
                        component: PageSubscriptionPaymentResponseComponent,
                        canActivate: [AccessGuard],
                        data: {
                            accessRequired: ['SOCIETY_SUBSCRIPTION_CREATE_IS_MINE']
                        }
                    },
                    {
                        path: 'payment-response/:response/:reference',
                        component: PageSubscriptionPaymentResponseComponent,
                        canActivate: [AccessGuard],
                        data: {
                            accessRequired: ['SOCIETY_SUBSCRIPTION_CREATE_IS_MINE']
                        }
                    },
                    {
                        path: 'promotion',
                        children: [
                            {
                                path: 'list',
                                component: PagePromotionSubscriptionListComponent,
                                canActivate: [AccessGuard],
                                data: {
                                    accessRequired: ['PROMOTION_SUBSCRIPTION_LIST']
                                }
                            },
                            {
                                path: 'create',
                                component: PagePromotionSubscriptionCreateComponent,
                                canActivate: [AccessGuard],
                                resolve: {
                                    societies: SocietyItemsResolver,
                                    subscriptions: SubscriptionItemsResolver,
                                },
                                data: {
                                    accessRequired: ['PROMOTION_SUBSCRIPTION_CREATE']
                                }
                            },
                            {
                                path: 'view/:id',
                                component: PagePromotionSubscriptionReadComponent,
                                canActivate: [AccessGuard],
                                resolve: {
                                    societies: SocietyItemsResolver,
                                    promotion: PromotionSubscriptionItemResolver,
                                },
                                data: {
                                    accessRequired: ['PROMOTION_SUBSCRIPTION_READ']
                                }
                            }
                        ]
                    },
                ]
            },
            {
                path: 'service',
                children: [
                    {
                        path: 'list',
                        component: PageServiceListComponent,
                        canActivate: [AccessGuard],
                        resolve: {
                            user: UserSelfItemResolver,
                            societyServices: ServiceSocietyItemsResolver
                        },
                        data: {
                            accessRequired: ['SERVICE_LIST']
                        }
                    },
                    {
                        path: 'payment-response/:serviceId/:response',
                        component: PageServiceSubscriptionPaymentResponseComponent,
                        canActivate: [AccessGuard],
                        resolve: {
                            service: ServiceItemResolver
                        },
                        data: {
                            accessRequired: ['SOCIETY_SERVICE_CREATE_IS_MINE']
                        }
                    },
                    {
                        path: 'payment-response/:serviceId/:response/:reference',
                        component: PageServiceSubscriptionPaymentResponseComponent,
                        canActivate: [AccessGuard],
                        resolve: {
                            service: ServiceItemResolver
                        },
                        data: {
                            accessRequired: ['SOCIETY_SERVICE_CREATE_IS_MINE']
                        }
                    },
                    {
                        path: 'subscription',
                        children: [
                            {
                                path: 'create/:serviceId',
                                component: PageServiceSubscriptionCreateComponent,
                                resolve: {
                                    service: ServiceItemResolver,
                                    societyServices: ServiceSocietyItemsResolver
                                },
                                canActivate: [AccessGuard, ServiceGuard],
                                data: {
                                    accessRequired: ['SOCIETY_SERVICE_CREATE_IS_MINE']
                                }
                            },
                            {
                                path: 'gift-voucher/:serviceId',
                                component: PageServiceGiftVoucherComponent,
                                resolve: {
                                    service: ServiceItemResolver
                                },
                                canActivate: [AccessGuard],
                                data: {
                                    accessRequired: ['SOCIETY_SERVICE_CREATE_IS_MINE']
                                }
                            },
                            {
                                path: 'update/:societyServiceId',
                                component: PageServiceSubscriptionUpdateComponent,
                                resolve: {
                                    societyService: SocietyServiceItemResolver,
                                    societyServices: ServiceSocietyItemsResolver
                                },
                                canActivate: [AccessGuard],
                                data: {
                                    accessRequired: ['SOCIETY_SERVICE_CREATE_IS_MINE']
                                }
                            },
                        ]

                    },
                    {
                        path: 'promotion',
                        children: [
                            {
                                path: 'list',
                                component: PagePromotionServiceListComponent,
                                canActivate: [AccessGuard],
                                data: {
                                    accessRequired: ['PROMOTION_SERVICE_LIST'] as AccessType[]
                                }
                            },
                            {
                                path: 'create',
                                component: PagePromotionServiceCreateComponent,
                                canActivate: [AccessGuard],
                                resolve: {
                                    societies: SocietyItemsResolver,
                                    services: ServiceItemsResolver,
                                },
                                data: {
                                    accessRequired: ['PROMOTION_SERVICE_CREATE'] as AccessType[]
                                }
                            },
                            {
                                path: 'update/:id',
                                component: PagePromotionServiceUpdateComponent,
                                canActivate: [AccessGuard],
                                resolve: {
                                    societies: SocietyItemsResolver,
                                    services: ServiceItemsResolver,
                                    promotionService: PromotionServiceItemResolver,
                                },
                                data: {
                                    accessRequired: ['PROMOTION_SERVICE_READ'] as AccessType[]
                                }
                            }
                        ]
                    },
                ]
            },
            {
                path: 'transporter',
                children: [
                    {
                        path: 'list',
                        component: PageTransporterListComponent,
                        canActivate: [AccessGuard],
                        data: {
                            accessRequired: ['TRANSPORTER_LIST_IS_MINE'] as AccessType[]
                        }
                    },
                    {
                        path: 'create',
                        component: PageTransporterCreateComponent,
                        canActivate: [AccessGuard],
                        data: {
                            accessRequired: ['TRANSPORTER_CREATE_IS_MINE'] as AccessType[]
                        }
                    },
                    {
                      path: 'update/:id',
                      component: PageTransporterUpdateComponent,
                      canActivate: [AccessGuard],
                      resolve: {
                        transporter: TransporterItemResolver,
                      },
                      data: {
                        accessRequired: ['TRANSPORTER_EDIT_IS_MINE'] as AccessType[]
                      }
                    }
                ]
            },
            {
                path: 'template-gift-voucher',
                children: [
                    {
                        path: 'list',
                        component: PageTemplateGiftVoucherListComponent,
                        canActivate: [AccessGuard],
                        data: {
                            accessRequired: ['TEMPLATE_GIFT_VOUCHER_LIST'] as AccessType[]
                        }
                    },
                    {
                        path: 'create',
                        component: PageTemplateGiftVoucherCreateComponent,
                        canActivate: [AccessGuard],
                        data: {
                            accessRequired: ['TEMPLATE_GIFT_VOUCHER_CREATE'] as AccessType[]
                        }
                    },
                    {
                        path: 'update/:id',
                        component: PageTemplateGiftVoucherUpdateComponent,
                        canActivate: [AccessGuard],
                        resolve: {
                            templateGiftVoucher: TemplateGiftVoucherResolver,
                        },
                        data: {
                            accessRequired: ['TEMPLATE_GIFT_VOUCHER_EDIT'] as AccessType[]
                        }
                    },
                ]
            },
            {
                path: 'invoice',
                children: [
                    {
                        path: 'list',
                        component: PageInvoiceListComponent
                    }
                ]
            },
            {
                path: 'collaborator',
                children: [
                    {
                        path: 'create',
                        component: PageCollaboratorCreateComponent,
                        resolve: {
                            user: UserSelfItemResolver,
                            accesses: AccessManageListResolver
                        },
                        canActivate: [AccessGuard],
                        data: {
                            accessRequired: ['USER_CREATE']
                        }
                    },
                    {
                        path: 'update/:id',
                        component: PageCollaboratorUpdateComponent,
                        resolve: {
                            user: UserSelfItemResolver,
                            collaborator: UserItemResolver,
                            accesses: AccessManageListResolver
                        },
                        canActivate: [AccessGuard],
                        data: {
                            accessRequired: ['USER_EDIT']
                        }
                    },
                    {
                        path: 'list',
                        component: PageCollaboratorListComponent,
                        resolve: {
                            society: SocietyItemSelfResolver
                        },
                        canActivate: [AccessGuard],
                        data: {
                            accessRequired: ['USER_CREATE']
                        }
                    }
                ]
            },
            {
                path: 'reference',
                children: [
                    {
                        path: 'read',
                        component: PageReferenceReadComponent,
                        canActivate: [AccessGuard],
                        data: {
                            accessRequired: [
                                {params: {}, accesses: ['SOCIETY_REFERENCE_SHARE'], roles: ['ROLE_PROVIDER']}
                            ]
                        }
                    }
                ]
            },
            {
                path: 'distributor',
                children: [
                    {
                        path: 'approval',
                        children: [
                            {
                                path: 'list',
                                component: PageDistributorApprovalListComponent,
                                canActivate: [AccessGuard],
                                data: {
                                    accessRequired: ['OFFER_CATALOG_REQUEST_LIST']
                                }
                            }
                        ]
                    }
                ]
            },
            {
                path: 'channel',
                children: [
                    {
                        path: 'list',
                        component: PageChannelListComponent,
                        resolve: {
                            society: SocietyItemSelfResolver
                        },
                        canActivate: [AccessGuard],
                        data: {
                            accessRequired: [
                                {params: {}, roles: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'], accesses: ['CHANNEL_LIST']},
                                {params: {}, roles: ['ROLE_OFFER_CREATOR', 'ROLE_OFFER_DISTRIBUTOR', 'ROLE_PROVIDER', 'ROLE_INSTITUTIONAL', 'ROLE_FEDERATION'] , accesses: ['CHANNEL_LIST_IS_MINE'] }
                            ]
                        }
                    },
                    {
                        path: 'create',
                        component: PageChannelCreateComponent,
                        resolve: {
                            user: UserSelfItemResolver,
                            society: SocietyItemSelfResolver
                        },
                        canActivate: [AccessGuard],
                        data: {
                            accessRequired: ['CHANNEL_CREATE_IS_MINE'],
                            mode: 'classic' as ChannelMode
                        }
                    },
                    {
                        path: 'update/:id',
                        component: PageChannelUpdateComponent,
                        resolve: {
                            channel: ChannelItemResolver,
                            user: UserSelfItemResolver,
                            society: SocietyItemSelfResolver
                        },
                        canActivate: [AccessGuard],
                        data: {
                            accessRequired: ['CHANNEL_EDIT_IS_MINE'],
                            mode: 'classic' as ChannelMode
                        }
                    },
                    {
                        path: 'read/:id',
                        component: PageChannelReadComponent,
                        resolve: {
                            channel: ChannelItemResolver,
                            user: UserSelfItemResolver
                        },
                        canActivate: [AccessGuard],
                        data: {
                            accessRequired: [
                                {params: {}, roles: ['ROLE_ADMIN', "ROLE_SUPER_ADMIN"], accesses: ['CHANNEL_READ']},
                                {params: {}, roles: ['ROLE_OFFER_CREATOR', 'ROLE_OFFER_DISTRIBUTOR', 'ROLE_PROVIDER', 'ROLE_INSTITUTIONAL', 'ROLE_FEDERATION'] , accesses: ['CHANNEL_READ_IS_MINE'] }
                             ]
                        }
                    },
                    {
                        path: 'restricted',
                        children: [
                            {
                                path: 'list',
                                component: PageChannelRestrictedListComponent,
                            },
                            {
                                path: 'create',
                                component: PageChannelCreateComponent,
                                resolve: {
                                    user: UserSelfItemResolver
                                },
                                canActivate: [AccessGuard],
                                data: {
                                    accessRequired: ['CHANNEL_CREATE'],
                                    mode: 'restricted' as ChannelMode
                                }
                            },
                            {
                                path: 'update/:id',
                                component: PageChannelUpdateComponent,
                                resolve: {
                                    channel: ChannelItemResolver
                                },
                                canActivate: [AccessGuard],
                                data: {
                                    accessRequired: ['CHANNEL_CREATE'],
                                    mode: 'restricted' as ChannelMode
                                }
                            }
                        ],
                    }
                ]
            },
            {
                path: 'offer',
                children: [
                    {
                        path: 'create',
                        component: PageOfferCreateComponent,
                        resolve: {
                            user: UserSelfItemResolver,
                            society: SocietyItemSelfResolver,
                            durations: OfferDurationItemsResolver
                        }
                    },
                    {
                        path: 'update/:id',
                        component: PageOfferUpdateComponent,
                        resolve: {
                            user: UserSelfItemResolver,
                            offer: OfferItemResolver,
                            society: SocietyItemSelfResolver,
                            durations: OfferDurationItemsResolver
                        }
                    },
                    {
                        path: 'read/:id',
                        component: PageOfferReadComponent,
                        resolve: {
                            offer: OfferItemResolver
                        }
                    },
                    {
                        path: 'attribute',
                        children: [
                            {
                                path: 'list',
                                component: PageOfferAttributeListComponent,
                                resolve: {
                                    offerAttributeTypes: OfferAttributeTypeListResolver
                                }
                            },
                            {
                                path: 'create',
                                component: PageOfferAttributeCreateComponent
                            },
                            {
                                path: 'update/:id',
                                component: PageOfferAttributeUpdateComponent,
                                resolve: {
                                    offer: OfferAttributeItemResolver
                                }
                            }
                        ]
                    },
                    {
                        path: 'list/:mode',
                        component: PageOfferListComponent,
                        resolve: {
                            society: SocietyItemSelfResolver
                        },
                        canActivate: [AccessGuard],
                        data: {
                            accessRequired: [
                                {params: {mode: 'reservoir'}, roles: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_OFFER_CREATOR', 'ROLE_OFFER_DISTRIBUTOR', 'ROLE_PROVIDER', 'ROLE_INSTITUTIONAL', 'ROLE_FEDERATION'], accesses: ['OFFER_LIST']},
                                {params: {mode: 'personnal-offers'}, roles: ['ROLE_OFFER_CREATOR'], accesses: ['OFFER_LIST_IS_MINE']},
                                {params: {mode: 'catalog'}, accesses: ['OFFER_CATALOG_LIST']},
                                {params: {mode: 'channel'}, accesses: ['OFFER_CATALOG_LIST']},
                            ]
                        }
                    },
                    {
                        path: 'markup',
                        children: [
                            {
                                path: 'list',
                                component: PageOfferMarkupListComponent,
                                resolve: {
                                    user: UserSelfItemResolver,
                                    society: SocietyItemSelfResolver
                                },
                                canActivate: [AccessGuard],
                                data: {
                                    accessRequired: ['OFFER_LIST_IS_MINE']
                                }
                            }
                        ]
                    },
                    {
                        path: 'markup/catalog',
                        children: [
                            {
                                path: 'list',
                                component: PageOfferMarkupCatalogListComponent,
                                resolve: {
                                    user: UserSelfItemResolver,
                                    society: SocietyItemSelfResolver
                                },
                                canActivate: [AccessGuard],
                                data: {
                                    accessRequired: [
                                        {params: {}, accesses: ['OFFER_CATALOG_EDIT_IS_MINE'], roles: ['ROLE_OFFER_DISTRIBUTOR']}
                                    ]
                                }
                            }
                        ]
                    },
                    {
                        path: 'network-provider',
                        children: [
                            {
                                path: 'list',
                                component: PageOfferGroupNetworkProviderListComponent,
                                resolve: {
                                    offerAttributeTypes: OfferAttributeTypeListResolver,
                                    offers: PageOfferNetworkProviderItemsResolver
                                }
                            },
                            {
                                path: 'create',
                                component: PageOfferGroupNetworkProviderCreateComponent,
                                resolve: {
                                    channels: ChannelItemsResolver,
                                    providers: SocietyProviderItemsResolver
                                }
                            },
                            {
                                path: 'update/:id',
                                component: PageOfferGroupNetworkProviderUpdateComponent,
                                resolve: {
                                    network: PageOfferNetworkProviderItemResolver,
                                    channels: ChannelItemsResolver,
                                    providers: SocietyProviderItemsResolver
                                }
                            }
                        ]
                    },
                    {
                        path: 'option',
                        children: [
                            {
                                path: 'list',
                                component: PageOfferOptionListComponent,
                                resolve: {
                                    society: SocietyItemSelfResolver
                                },
                                canActivate: [AccessGuard],
                                data: {
                                    accessRequired: ['OFFER_OPTION_LIST_IS_MINE']
                                }
                            },
                            {
                                path: 'create',
                                component: PageOfferOptionCreateComponent,
                                resolve: {
                                    user: UserSelfItemResolver,
                                    society: SocietyItemSelfResolver
                                },
                                canActivate: [AccessGuard],
                                data: {
                                    accessRequired: ['OFFER_OPTION_CREATE_IS_MINE']
                                }
                            },
                            {
                                path: 'update/:id',
                                component: PageOfferOptionUpdateComponent,
                                resolve: {
                                    user: UserSelfItemResolver,
                                    society: SocietyItemSelfResolver,
                                    option: OfferOptionItemResolver
                                },
                                canActivate: [AccessGuard],
                                data: {
                                    accessRequired: ['OFFER_OPTION_EDIT_IS_MINE']
                                }
                            }
                        ]
                    },
                    {
                        path: 'permanent-option',
                        children: [
                            {
                                path: 'list',
                                component: PageOfferPermanentOptionListComponent,
                                resolve: {
                                    society: SocietyItemSelfResolver
                                },
                                canActivate: [AccessGuard],
                                data: {
                                    accessRequired: ['OFFER_PERMANENT_OPTION_LIST_IS_MINE']
                                }
                            },
                            {
                                path: 'create',
                                component: PageOfferPermanentOptionFormComponent,
                                resolve: {
                                    user: UserSelfItemResolver,
                                    society: SocietyItemSelfResolver
                                },
                                canActivate: [AccessGuard],
                                data: {
                                    accessRequired: ['OFFER_PERMANENT_OPTION_CREATE_IS_MINE'],
                                    crudAction: 'create' as CrudAction
                                }
                            },
                            {
                                path: 'update/:id',
                                component: PageOfferPermanentOptionFormComponent,
                                resolve: {
                                    user: UserSelfItemResolver,
                                    society: SocietyItemSelfResolver,
                                    option: OfferPermanentOptionResolver
                                },
                                canActivate: [AccessGuard],
                                data: {
                                    accessRequired: ['OFFER_PERMANENT_OPTION_EDIT_IS_MINE'],
                                    crudAction: 'update' as CrudAction
                                }
                            }
                        ]
                    },
                ]
            },
            {
                path: 'offer-creator',
                children: [
                    {
                        path: 'list',
                        component: PageOfferCreatorListComponent,
                        canActivate: [AccessGuard],
                        data: {
                            accessRequired: ['OFFER_CREATOR_LIST'] as AccessType[]
                        }
                    },
                    {
                        path: 'read/:id',
                        component: PageOfferCreatorReadComponent,
                        canActivate: [AccessGuard],
                        resolve: {
                            offerCreator: OfferCreatorItemResolver
                        },
                        data: {
                            accessRequired: ['OFFER_CREATOR_READ'] as AccessType[]
                        }
                    },
                ]
            },
            {
                path: 'gift-voucher',
                children: [
                    {
                        path: 'list',
                        component: PageGiftVoucherListComponent,
                        resolve: {
                            giftVouchers: PageGiftVoucherItemsResolver
                        },
                        canActivate: [AccessGuard],
                        data: {
                            accessRequired: [
                                {params: {}, roles: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'], accesses: ['GIFT_VOUCHER_LIST']},
                                {params: {}, roles: ['ROLE_OFFER_CREATOR', 'ROLE_OFFER_DISTRIBUTOR', 'ROLE_PROVIDER', 'ROLE_INSTITUTIONAL', 'ROLE_FEDERATION'] , accesses: ['GIFT_VOUCHER_LIST_IS_MINE', 'GIFT_VOUCHER_NO_SERVICE_LIST_IS_MINE'] }
                            ]
                        }
                    },
                    {
                        path: 'read/:id',
                        component: PageGiftVoucherReadComponent,
                        canActivate: [AccessGuard],
                        resolve: {
                            giftVoucher: PageGiftVoucherItemResolver,
                            society: SocietyItemSelfResolver,
                        },
                        data: {
                            accessRequired: [
                                {params: {}, roles: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'], accesses: ['GIFT_VOUCHER_READ']},
                                {params: {}, roles: ['ROLE_OFFER_CREATOR', 'ROLE_OFFER_DISTRIBUTOR', 'ROLE_PROVIDER', 'ROLE_INSTITUTIONAL', 'ROLE_FEDERATION'] , accesses: ['GIFT_VOUCHER_READ_IS_MINE', 'GIFT_VOUCHER_NO_SERVICE_READ_IS_MINE'] }
                            ]
                        }
                    },
                    {
                        path: 'periods/:id',
                        component: PageGiftVoucherPeriodComponent,
                        resolve: {
                            giftVoucher: PageGiftVoucherItemResolver
                        }
                    },
                ]
            },
            {
                path: 'booking',
                children: [
                    {
                        path: 'list',
                        component: PageBookingListComponent,
                        resolve: {
                            bookings: PageBookingItemsResolver
                        },
                        canActivate: [AccessGuard],
                        data: {
                            accessRequired: [
                                {params: {}, roles: ['ROLE_ADMIN', "ROLE_SUPER_ADMIN"], accesses: ['BOOKING_LIST']},
                                {params: {}, roles: ['ROLE_OFFER_CREATOR', 'ROLE_OFFER_DISTRIBUTOR', 'ROLE_PROVIDER', 'ROLE_INSTITUTIONAL', 'ROLE_FEDERATION'] , accesses: ['BOOKING_LIST_IS_MINE'] }
                            ]
                        }
                    },
                    {
                        path: 'read/:id',
                        component: PageBookingReadComponent,
                        resolve: {
                            booking: PageBookingItemResolver,
                            society: SocietyItemSelfResolver
                        }
                    }
                ]
            },
            {
                path: 'society',
                children: [
                    {
                        path: 'distributor',
                        children: [
                            {
                                path: 'list',
                                component: PageSocietyDistributorListComponent,
                                resolve: {
                                    society: SocietyItemSelfResolver
                                },
                                canActivate: [AccessGuard],
                                data: {
                                    accessRequired: ['SOCIETY_DISTRIBUTOR_LIST_IS_MINE']
                                }
                            },
                            {
                                path: 'update/:id',
                                component: PageSocietyDistributorUpdateComponent,
                                resolve: {
                                    societyDistributor: SocietyDistributorItemResolver
                                },
                                canActivate: [AccessGuard],
                                data: {
                                    accessRequired: ['SOCIETY_DISTRIBUTOR_EDIT_IS_MINE']
                                }
                            }
                        ]
                    },
                    {
                        path: 'provider',
                        children: [
                            {
                                path: 'list',
                                component: PageSocietyProviderListComponent,
                                resolve: {
                                    society: SocietyItemSelfResolver
                                },
                                canActivate: [AccessGuard],
                                data: {
                                    accessRequired: ['SOCIETY_PROVIDER_LIST_IS_MINE']
                                }
                            },
                            {
                                path: 'read/:id',
                                component: PageSocietyProviderReadComponent,
                                resolve: {
                                    society: SocietyItemSelfResolver,
                                    societyProvider: SocietyProviderItemResolver
                                },
                                canActivate: [AccessGuard],
                                data: {
                                    accessRequired: ['SOCIETY_PROVIDER_READ_IS_MINE']
                                }
                            }
                        ]
                    }
                ]
            },
            {
                path: 'promotion',
                children: [
                    {
                        path: 'list',
                        component: PagePromotionListComponent,
                        resolve: {
                            society: SocietyItemSelfResolver
                        },
                        canActivate: [AccessGuard],
                        data: {
                            accessRequired: ['PROMOTION_LIST_IS_MINE']
                        }
                    },
                    {
                        path: 'create',
                        component: PagePromotionCreateComponent,
                        resolve: {
                            user: UserSelfItemResolver,
                            society: SocietyItemSelfResolver
                        },
                        canActivate: [AccessGuard],
                        data: {
                            accessRequired: ['PROMOTION_CREATE_IS_MINE']
                        }
                    },
                    {
                        path: 'update/:id',
                        component: PagePromotionUpdateComponent,
                        resolve: {
                            user: UserSelfItemResolver,
                            society: SocietyItemSelfResolver,
                            promotion: PromotionItemResolver
                        },
                        canActivate: [AccessGuard],
                        data: {
                            accessRequired: ['PROMOTION_EDIT_IS_MINE']
                        }
                    }
                ]
            },
            {
                path: 'card',
                children: [
                    {
                        path: 'read',
                        component: PageCardReadComponent
                    },
                    {
                        path: 'create',
                        component: PageCardCreateComponent,
                        resolve: {
                            society: SocietyItemSelfResolver
                        }
                    },
                    {
                        path: 'update',
                        component: PageCardUpdateComponent,
                        resolve: {
                            society: SocietyItemSelfResolver
                        }
                    },
                    {
                        path: 'payment-response/:response',
                        component: PageCardPaymentResponseComponent
                    },
                    {
                        path: 'payment-response/:response/:reference',
                        component: PageCardPaymentResponseComponent
                    }
                ]
            },
            {
                path: 'availability',
                children: [
                    {
                        path: 'offer',
                        children: [
                            {
                                path: 'list',
                                component: PageAvailabilityOfferListComponent,
                                canActivate: [AccessGuard],
                                data: {
                                    accessRequired: ['OFFER_LIST_IS_MINE']
                                }
                            },
                            {
                                path: 'read/:id',
                                component: PageAvailabilityOfferReadComponent,
                                resolve: {
                                    offer: OfferItemResolver
                                },
                                canActivate: [AccessGuard],
                                data: {
                                    accessRequired: ['OFFER_READ_IS_MINE']
                                }
                            },
                            {
                                path: 'update/:id',
                                component: PageAvailabilityOfferUpdateComponent,
                                resolve: {
                                    offer: OfferItemResolver
                                },
                                canActivate: [AccessGuard],
                                data: {
                                    accessRequired: ['OFFER_EDIT_IS_MINE']
                                }
                            }
                        ]
                    }
                ]
            },
            {
                path: 'network-offer-creator',
                children: [
                    {
                        path: 'list',
                        component: PageNetworkOfferCreatorListComponent
                    },
                    {
                        path: 'create',
                        component: PageNetworkOfferCreatorCreateComponent
                    },
                    {
                        path: 'update/:id',
                        component: PageNetworkOfferCreatorUpdateComponent,
                        resolve: {
                            networkOfferCreator: NetworkOfferCreatorItemResolver
                        }
                    }
                ]
            },
            {
                path: 'registration-affiliation',
                children: [
                    {
                        path: 'list',
                        component: PageRegistrationAffiliationListComponent
                    },
                    {
                        path: 'create',
                        component: PageRegistrationAffiliationCreateComponent
                    },
                    {
                        path: 'update/:id',
                        component: PageRegistrationAffiliationUpdateComponent,
                        resolve: {
                            registrationAffiliation: RegistrationAffiliationItemResolver
                        }
                    }
                ]
            },
            {
                path: 'quotation',
                children: [
                    {
                        path: 'create',
                        component: PageQuotationCreateComponent,
                        canActivate: [AccessGuard],
                        data: {
                            accessRequired: ['OFFER_CREATOR_LIST'] as AccessType[]
                        },
                        resolve: {
                            offerCreator: SocietyOfferCreatorItemResolver
                        }
                    },
                    {
                        path: 'list',
                        component: PageQuotationListComponent,
                        canActivate: [AccessGuard],
                        data: {
                            accessRequired: [
                                { params: {}, roles: ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'], accesses: ['QUOTATION_LIST'] },
                                { params: {}, roles: ['ROLE_OFFER_CREATOR', 'ROLE_OFFER_DISTRIBUTOR', 'ROLE_PROVIDER', 'ROLE_INSTITUTIONAL', 'ROLE_FEDERATION'] , accesses: ['QUOTATION_LIST_IS_MINE'] }
                            ]
                        }
                    },
                    {
                        path: 'read/:id',
                        component: PageQuotationReadComponent,
                        resolve: {
                            society: SocietyItemSelfResolver,
                            quotation: PageQuotationItemResolver
                        }
                    }
                ]
            },
            {
                path: '**',
                component: PageSigninComponent
            }
        ],
        canActivate: [
            AuthenticationGuard,
            SubscriptionGuard
        ]
    },
    {
        path: 'menu',
        canActivate: [
            AuthenticationGuard,
            SubscriptionGuard
        ],
        children: [
            {
                path: 'create',
                component: PageMenuCreateComponent,
                resolve: {
                    societies: SocietyItemsResolver
                }
            },
            {
                path: 'update/:id',
                component: PageMenuUpdateComponent,
                resolve: {
                    menu: MenuItemResolver,
                    societies: SocietyItemsResolver
                }
            },
            {
                path: 'list',
                component: PageMenuListComponent,
                resolve: {
                    menu: MenuItemsResolver
                }
            }
        ]
    },
    {
        path: 'page',
        canActivate: [
            AuthenticationGuard,
            SubscriptionGuard
        ],
        children: [
            {
                path: 'create',
                component: PageCreateComponent,
                resolve: {
                    societies: SocietyItemsResolver
                }
            },
            {
                path: 'update/:id',
                component: PageUpdateComponent,
                resolve: {
                    page: PageItemResolver,
                    societies: SocietyItemsResolver
                }
            },
            {
                path: 'list',
                component: PageListComponent,
                resolve: {
                    page: PageItemsResolver
                }
            },
            {
                path: 'read/:id',
                component: PageReadComponent,
                resolve: {
                    page: PageItemResolver
                    // cmsElements: CmsItemsResolver
                }
            },
        ]
    },
    {
        path: 'category',
        canActivate: [
            AuthenticationGuard,
            SubscriptionGuard
        ],
        children: [
            {
                path: 'create',
                component: PageCategoryCreateComponent,
                resolve: {
                    societies: SocietyItemsResolver,
                    pages: PageItemsResolver
                }
            },
            {
                path: 'update/:id',
                component: PageCategoryUpdateComponent,
                resolve: {
                    category: PageCategoryItemResolver,
                    societies: SocietyItemsResolver,
                    pages: PageItemsResolver
                }
            },
            {
                path: 'list',
                component: PageCategoryListComponent,
                resolve: {
                    category: PageCategoryItemsResolver
                }
            },
            {
                path: 'read/:id',
                component: PageCategoryReadComponent,
                resolve: {
                    category: PageCategoryItemResolver
                }
            }
        ]
    },
    {
        path: '**',
        component: PageSigninComponent
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
