<div class="row">

    <div class="col_12">

        <!-- Destinations -->

        <div class="filter_item">
            <app-core-select-array-multiple-filter
                #destinationFilter
                [builder]="filterBuilder"
                [label]="'destination.plural.value' | translate"
                [key]="OfferCreatorFilterKey.Destination"
                [operator]="'in'"
                [itemValueProperty]="'id'"
                [itemLabelProperty]="'label'"
                [items]="offerAttributeRegions"
            ></app-core-select-array-multiple-filter>
        </div>

        <!-- Cibles -->

        <div class="filter_item">
            <app-core-select-array-multiple-filter
                #targetFilter
                [builder]="filterBuilder"
                [label]="'target.plural.value' | translate"
                [key]="OfferCreatorFilterKey.Target"
                [operator]="'in'"
                [itemValueProperty]="'id'"
                [itemLabelProperty]="'label'"
                [items]="offerCustomerTypologies"
            ></app-core-select-array-multiple-filter>
        </div>

        <!-- Réseaux créateur d'offres -->

        <div class="filter_item network_offer_creator">
            <app-core-select-array-multiple-filter
                #networkOfferCreatorFilter
                [builder]="filterBuilder"
                [label]="'offer.list.filter.offerCreatorNetwork.value' | translate"
                [key]="OfferCreatorFilterKey.NetworkOfferCreator"
                [operator]="'in'"
                [itemValueProperty]="'id'"
                [itemLabelProperty]="'label'"
                [items]="offerNetworkOfferCreators"
            ></app-core-select-array-multiple-filter>
        </div>

        <!-- Types d'offres -->

        <div class="filter_item">
            <app-core-select-array-multiple-filter
                #offerTypeFilter
                [builder]="filterBuilder"
                [label]="'offer.plural.type.plural.value' | translate"
                [key]="OfferCreatorFilterKey.OfferType"
                [operator]="'in'"
                [itemValueProperty]="'id'"
                [itemLabelProperty]="'label'"
                [items]="offerTypes"
            ></app-core-select-array-multiple-filter>
        </div>

        <!-- Avec ou sans abonnement -->

        <ng-container *ngIf="userService.hasOneOfThisRoles(currentUser, ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN'])">

            <div class="filter_item">
                <app-core-select-array-filter
                    #hasSubscriptionFilter
                    [builder]="filterBuilder"
                    [label]="'offerCreator.filter.subscription.value' | translate"
                    [key]="OfferCreatorFilterKey.HasSubscription"
                    [operator]="'eq'"
                    [itemValueProperty]="'value'"
                    [itemLabelProperty]="'label'"
                    [items]="subscriptionStatus"
                ></app-core-select-array-filter>
            </div>

        </ng-container>

        <!-- Nom du créateur d'offre -->

        <div class="filter_item">
            <app-core-text-filter
                #nameFilter
                [builder]="filterBuilder"
                [label]="'offerCreator.name.value' | translate"
                [key]="OfferCreatorFilterKey.Name"
                [operator]="'lk'"
            ></app-core-text-filter>
        </div>

        <!-- Présent dans mon catalogue -->

        <ng-container *ngIf="userService.hasOneOfThisRoles(currentUser, ['ROLE_OFFER_DISTRIBUTOR', 'ROLE_INSTITUTIONAL', 'ROLE_FEDERATION'])">

            <div class="filter_item">
                <app-core-checkbox-boolean-filter
                    #inCatalogFilter
                    [builder]="filterBuilder"
                    [label]="'offerCreator.inCatalog.mine.value' | translate"
                    [key]="OfferCreatorFilterKey.InCatalog"
                ></app-core-checkbox-boolean-filter>
            </div>

        </ng-container>

        <!-- Labellisé RSE -->

        <div class="filter_item">
            <app-core-checkbox-boolean-filter
                #hasRseLabelFilter
                [builder]="filterBuilder"
                [label]="'rse.labelled.value' | translate"
                [key]="OfferCreatorFilterKey.HasRseLabel"
            ></app-core-checkbox-boolean-filter>
        </div>

    </div>

</div>

<div class="row" style="margin-top: 18px">

    <div class="col_12">

        <!-- Réinitialisation des filtres -->

        <button type="button" class="btn_cta btn_small" (click)="reset()">{{ 'offer.list.filter.reinitialise.value' | translate }}</button>

    </div>
</div>
